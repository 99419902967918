<template>
  <!------------------------   관리자 정보 수정 팝업   ---------------------------->
  <div v-if="popShow && openPopType == 'update'" class="pop-drag">
    <div class="popup-mask"></div>
    <div
      id="updatePopContent"
      class="pop-content pop-ani pop-drag"
      style="width:500px;"
    >
      <div class="pop-head">
        <h2>{{ $t('operation.operator.updatePopUp.title') }}</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="updatePopForm">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="106px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    {{ $t('operation.operator.updatePopUp.label1')
                    }}<i class="star">*</i>
                  </th>
                  <td>
                    <label id="u_id_label">{{ updateOperator.userId }}</label>
                  </td>
                </tr>
                <tr>
                  <th>{{ operatorName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="operatorName"
                      :rules="{ required: true, max: 100 }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="updateOperator.userName"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ cellphone }}<i class="star">*</i></th>
                  <td>
                    <div class="tel-fill-style">
                      <select
                        class="input-24"
                        id="u_mphon_no1"
                        name="u_mphon_no1"
                        v-model="updateOperator.mobileNo1"
                      >
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                      </select>
                      <validate-form
                        type="number"
                        :label="cellphone"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="phoneInputErr"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="updateOperator.mobileNo2"
                        @keydown="inputNumberCheck"
                      />
                      <validate-form
                        type="number"
                        :label="cellphone"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="phoneInputErr"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="updateOperator.mobileNo3"
                        @keydown="inputNumberCheck"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ email }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="email"
                      :rules="{ required: true, email: true }"
                      inputClass="input-24 w-100"
                      maxlength="50"
                      v-model="updateOperator.email"
                      name="u_email_adr"
                      dataType="EMAIL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('operation.operator.updatePopUp.label5')
                    }}<i class="star">*</i>
                  </th>
                  <td>
                    <select class="input-24 w-100" disabled value="'group'">
                      <option value="group">{{
                        updateOperator.userGroupNo
                      }}</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnSubmit"
              class="bt-line30 cr-orange"
              id="button_popup_update_operator_yes"
              @click="submit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import ValidateForm from '@/components/ValidateForm.vue';
import { updateOperator } from '@/api/operationApi';

export default {
  mixins: [CommonMixins],
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    itemList: {
      type: Object,
      required: true,
    },
    nowPerPage: {
      type: Number,
      required: true,
    },
    authGroup: {
      type: Object,
      required: true,
    },
    clickUserId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    updateOperator: {
      email: '',
      mobileNo1: '',
      mobileNo2: '',
      mobileNo3: '',
      userId: '',
      userName: '',
      userGroupNo: '',
    },
    updatePhoneNo1: '',
    updatePhoneNo2: '',
    updatePhoneNo3: '',
    errorData: {},
  }),
  components: {
    ValidateForm,
  },
  computed: {
    operatorName() {
      return this.$i18n.t('operation.operator.updatePopUp.label2');
    },
    cellphone() {
      return this.$i18n.t('operation.operator.updatePopUp.label3');
    },
    email() {
      return this.$i18n.t('operation.operator.updatePopUp.label4');
    },
    phoneInputErr() {
      return { regex: this.$i18n.t('common.validate.phone-format') };
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
  },
  methods: {
    closePopup() {
      this.$emit('close', false);
    },
    setUpdateSrvc() {
      this.updateOperator.email = this.itemList.email;
      this.updateOperator.userName = this.itemList.userName;
      this.updateOperator.userId = this.itemList.userId;
      this.updateOperator.mobileNo1 = this.getPhoneTextArr(
        this.itemList.mobileNo,
      )[0];
      this.updateOperator.mobileNo2 = this.getPhoneTextArr(
        this.itemList.mobileNo,
      )[1];
      this.updateOperator.mobileNo3 = this.getPhoneTextArr(
        this.itemList.mobileNo,
      )[2];
      this.updateOperator.userGroupNo = this.authGroup[
        this.itemList.userGroupNo
      ];
    },
    resetUpdateSrvc() {
      Object.keys(this.updateOperator).map(k => (this.updateOperator[k] = ''));
    },
    async submit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      const passwordChk = await this.$dialogs.password();
      if (passwordChk.ok) {
        try {
          const params = {
            email: this.updateOperator.email,
            mobileNo:
              this.updateOperator.mobileNo1 +
              this.updateOperator.mobileNo2 +
              this.updateOperator.mobileNo3,
            userId: this.updateOperator.userId,
            userName: this.updateOperator.userName,
          };
          await updateOperator(params).then(({ data }) => {
            if (data.valid) {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.update-success'))
                .then(() => {
                  const reqbody = {
                    page: 1,
                    rows: this.nowPerPage,
                    sidx: null,
                    sord: 'asc',
                    userId: '',
                    userName: '',
                    srvcId: '',
                  };
                  this.$emit('getOperatorList', reqbody);
                  this.$emit('getOperDetail', { userId: this.clickUserId });
                  this.$emit('close', false);
                  this.resetUpdateSrvc();
                });
            }
          });
        } catch (error) {
          console.log('error', error);
        }
      }
    },
  },
  watch: {
    itemList() {
      if (!this.isEmptyObject(this.itemList)) {
        this.setUpdateSrvc();
      } else {
        this.resetUpdateSrvc();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
