<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-- 시스템 설정 목록 -->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :reloadToggle="reloadToggle"
          :loading="loading"
          :customSlotInfo="customSlotInfo"
          @rowClick="rowClick"
          @getDataFromApi="searchSystemList"
        >
          <!-- 검색 옵션 -->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('operation.system.search-condition1') }}</label
            >
            <select
              v-model="searchOpts.setupTyCode"
              class="sort-input-select"
              style="width:100px"
            >
              <option value="">{{ $t('common.all') }}</option>
              <option
                v-for="(item, index) in syssetTypeList"
                :key="index"
                :value="item.codeId"
                >{{ item.codeName }}</option
              >
            </select>
            <label class="sort-label"
              >· {{ $t('operation.system.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.setupNm"
              type="text"
              class="sort-input-text"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <!-- subHeaderItem -->
          </template>
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
          <!-- customSlot -->
          <template v-slot:setupNm_custom="{ item }">
            <td class="text-left td-ellipsis" :title="item.setupNm">
              {{ item.setupNm || '-' }}
            </td>
          </template>
          <template v-slot:setupValue_custom="{ item }">
            <td class="text-left td-ellipsis" :title="item.setupValue">
              {{ item.setupValue || '-' }}
            </td>
          </template>
          <template v-slot:setupDc_custom="{ item }">
            <td class="text-left td-ellipsis" :title="item.setupDc">
              {{ item.setupDc || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-- 상세 정보 -->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- customSlot -->
          <template v-slot:setupValue_tab_custom="{ item }">
            <td>
              <textarea
                class="input-24 w-100 board-readonly"
                style="height:100px; overflow-y:auto;"
                readonly="readonly"
                :value="item.value || '-'"
                disabled
              ></textarea>
            </td>
          </template>
          <template v-slot:setupDc_tab_custom="{ item }">
            <td>
              <textarea
                class="input-24 w-100 board-readonly"
                style="height:100px; overflow-y:auto;"
                readonly="readonly"
                :value="item.value || '-'"
                disabled
              ></textarea>
            </td>
          </template>
          <template v-slot:fileNm_tab_custom>
            <td>
              <span id="d_fileNm"
                ><a
                  v-for="(item, index) in fileList.fileDetailList"
                  :key="index"
                  @click="dwldUrl(item.fileId, item.fileOdrg)"
                  >{{ item.originFileNm || '-' }}<br /></a
              ></span>
            </td>
          </template>
          <template #detail_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray ml-1"
                  @click="deleteSubmit"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!-- 시스템 설정 팝업 -->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <validation-observer ref="observer">
            <form enctype="multipart/form-data">
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="95px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      {{ $t('operation.system.popUp.label1')
                      }}<i class="star">*</i>
                    </th>
                    <td>
                      <select
                        v-model="popup.submitItem.setupTyCode"
                        class="input-24"
                        name="setupTyCode"
                        style="width:100px"
                      >
                        <option
                          v-for="(item, index) in syssetTypeList"
                          :key="index"
                          :value="item.codeId"
                          >{{ item.codeName }}</option
                        >
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ settingName }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="settingName"
                        v-model="popup.submitItem.setupNm"
                        name="setupNm"
                        :rules="{
                          required: true,
                          max: 100,
                        }"
                        maxlength="100"
                        inputClass="input-24 w-100"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ settingVal }}</th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="settingVal"
                        v-model="popup.submitItem.setupValue"
                        name="setupValue"
                        :rules="{ max: 300 }"
                        inputClass="input-24 w-100"
                        maxlength="300"
                        inputStyle="height:100px; overflow-y:auto;"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ settingDesc }}</th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="settingDesc"
                        v-model="popup.submitItem.setupDc"
                        name="setupDc"
                        :rules="{ max: 300 }"
                        inputClass="input-24 w-100"
                        maxlength="300"
                        inputStyle="height:100px; overflow-y:auto;"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('operation.system.popUp.label5') }}</th>
                    <td id="insertFile">
                      <div id="u_updateFile" v-if="popup.type == 'update'">
                        <div
                          style="margin-top: 2px"
                          v-for="(item, index) in fileList.fileDetailList"
                          :key="index"
                          :id="`u_file_${index}`"
                        >
                          <input
                            v-show="item.originFileNm !== '-'"
                            type="button"
                            class="bt-line22 btnDelete valid bt-num-minus"
                            style="float: right;"
                            @click="deleteFile(index)"
                          />
                          <a
                            v-show="item.originFileNm !== '-'"
                            @click="dwldUrl(item.fileId, item.fileOdrg)"
                            >{{ item.originFileNm || '-' }}<br />
                          </a>
                        </div>
                      </div>
                      <div
                        v-for="index in fileIdx"
                        :key="index"
                        :id="`div_file_${index}`"
                        class="mt-3"
                      >
                        <input
                          :id="`ipopInsertFileNm_${index}`"
                          type="text"
                          class="input-24"
                          style="width:243px"
                          readonly="readonly"
                        />
                        <input
                          type="file"
                          :ref="`file_${index}`"
                          :id="`ipopFile_${index}`"
                          style="display:none"
                          @change="changeFile(index)"
                        />
                        <input
                          type="button"
                          class="bt-line24"
                          :value="btnSelect"
                          @click="insertFile(index)"
                        />
                        <input
                          type="button"
                          class="bt-line22 bt-num-plus btnAddRow ml-2"
                          :class="
                            index == fileIdx ? 'bt-num-plus' : 'bt-num-minus'
                          "
                          :id="`file_${index}`"
                          :value="index == fileIdx ? '+' : '-'"
                          @click="appendRow($event)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </validation-observer>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="popup.type == 'insert' ? btnRegister : btnSubmit"
              class="bt-line30 cr-orange"
              @click="popup.type == 'insert' ? insertSubmit() : updateSubmit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import ValidateForm from '@/components/ValidateForm.vue';
import {
  getSystemList,
  getFileDetail,
  addSysSet,
  modifySysSet,
  deleteFile,
  deleteSysSet,
} from '@/api/operationApi';
import CommonMixins from '@/mixins/CommonMixins';
import { getCommonCode } from '@/api/commonApi';
import { COMMON_GROUP_CODE } from '@/store/properties';

export default {
  name: 'SystemPage',
  components: { TwoPanel, TableListComponent, TableTabComponent, ValidateForm },
  mixins: [CommonMixins],
  data() {
    return {
      // 좌측 패널 테이블
      loading: false,
      reloadToggle: true,
      // custom Slot
      customSlotInfo: [
        { name: 'setupNm', slotName: 'item.setupNm' },
        { name: 'setupValue', slotName: 'item.setupValue' },
        { name: 'setupDc', slotName: 'item.setupDc' },
      ],
      tableItems: [],
      // 검색 정보
      syssetTypeList: [],
      searchOpts: {
        setupTyCode: '',
        setupNm: '',
      },
      // row
      nowPerPage: 30,
      totalRecords: 0,
      totalPages: 0,
      // 우측 패널 테이블
      tabOverlay: false,
      tabItems: [],
      fileList: {
        fileDetailList: [{ originFileNm: '-', fileId: '-' }],
      },
      popup: {
        type: '',
        show: false,
        submitItem: {
          sysSetupSn: '',
          setupTyCode: '1',
          setupNm: '',
          setupValue: '',
          setupDc: '',
          fileSn: '',
        },
      },
      fileIdx: 1,
    };
  },
  created() {
    this.getCommonCode();
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('operation.system.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.system.leftPanel.table-column1'),
          value: 'sysSetupSn',
          width: '9%',
        },
        {
          text: this.$i18n.t('operation.system.leftPanel.table-column2'),
          value: 'setupTyName',
          width: '13%',
        },
        {
          text: this.$i18n.t('operation.system.leftPanel.table-column3'),
          value: 'setupNm',
          width: '18%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.system.leftPanel.table-column4'),
          value: 'setupValue',
          width: '30%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.system.leftPanel.table-column5'),
          value: 'setupDc',
          width: '30%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.system.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'detail',
          headers: [
            {
              text: this.$i18n.t('operation.system.rightPanel.header1'),
              width: '35%',
            },
            {
              text: this.$i18n.t('operation.system.rightPanel.header2'),
              width: '65%',
            },
          ],
          selectedItem: {
            sysSetupSn: {
              name: this.$i18n.t('operation.system.rightPanel.label1'),
              value: '',
            },
            setupTyName: {
              name: this.$i18n.t('operation.system.rightPanel.label2'),
              value: '',
            },
            setupNm: {
              name: this.$i18n.t('operation.system.rightPanel.label3'),
              value: '',
            },
            setupValue: {
              name: this.$i18n.t('operation.system.rightPanel.label4'),
              value: '',
              isCustom: true,
            },
            setupDc: {
              name: this.$i18n.t('operation.system.rightPanel.label5'),
              value: '',
              isCustom: true,
            },
            fileNm: {
              name: this.$i18n.t('operation.system.rightPanel.label6'),
              value: '',
              isCustom: true,
            },
            crtrId: {
              name: this.$i18n.t('operation.system.rightPanel.label7'),
              value: '',
            },
            creatDt: {
              name: this.$i18n.t('operation.system.rightPanel.label8'),
              value: '',
            },
            updusrId: {
              name: this.$i18n.t('operation.system.rightPanel.label9'),
              value: '',
            },
            updtDt: {
              name: this.$i18n.t('operation.system.rightPanel.label10'),
              value: '',
            },
          },
        },
      ];
    },
    settingName() {
      return this.$i18n.t('operation.system.popUp.label2');
    },
    settingVal() {
      return this.$i18n.t('operation.system.popUp.label3');
    },
    settingDesc() {
      return this.$i18n.t('operation.system.popUp.label4');
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnSelect() {
      return this.$i18n.t('common.btn.select');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.getCommonCode();
      this.setSetupTyName();
      this.clearFileList();
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 설정 타입 목록 조회
    async getCommonCode() {
      const { data } = await getCommonCode(COMMON_GROUP_CODE.CODE_SYSSET_TYPE);
      this.syssetTypeList = data;
    },
    // 시스템 설정 관리 목록 조회
    async searchSystemList(searchParams) {
      this.loading = true;
      const { rows, page, sord, sidx, setupTyCode, setupNm } = searchParams;
      const params = {
        rows,
        page,
        sidx,
        sord,
        setupTyCode,
        setupNm,
      };
      await getSystemList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
          this.setSetupTyName();
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async rowClick(item) {
      this.tabOverlay = true;
      const params = {
        fileSn: typeof item.fileSn === 'number' ? item.fileSn : false,
      };
      if (params.fileSn === false) {
        this.fileList.fileDetailList.splice(1);
        this.fileList.fileDetailList[0].originFileNm = '-';
        this.fileList.fileDetailList[0].fileId = '-';
        this.fileList.fileId = '';
        Object.keys(this.tabItems[0].selectedItem).forEach(key => {
          this.tabItems[0].selectedItem[key].value = item[key];
        });
        this.tabOverlay = false;
        return;
      }
      await getFileDetail(params).then(({ data }) => {
        this.fileList = data;
        Object.keys(this.tabItems[0].selectedItem).forEach(key => {
          this.tabItems[0].selectedItem[key].value = item[key];
        });
      });
      this.tabOverlay = false;
    },
    openPopup(type) {
      this.popup.type = type;
      if (type === 'insert') {
        this.popup.title = this.$i18n.t('operation.system.popUp.title-insert');
      } else {
        this.popup.title = this.$i18n.t('operation.system.popUp.title-update');
        const selectedItem = this.tabItems[0].selectedItem;
        if (!this.tabItems[0].selectedItem.sysSetupSn.value) {
          const target = this.getPostWord(
            this.$i18n.t('operation.system.target-system'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-update', { target }),
          );
          return;
        }
        Object.keys(this.popup.submitItem).forEach(key => {
          this.popup.submitItem[key] = selectedItem[key]?.value;
        });
        switch (selectedItem['setupTyName'].value) {
          case 'Document':
            this.popup.submitItem['setupTyCode'] = 2;
            break;
          case 'APP':
            this.popup.submitItem['setupTyCode'] = 3;
            break;
          case 'Config':
            this.popup.submitItem['setupTyCode'] = 4;
            break;
          case '기타':
          case 'Others':
            this.popup.submitItem['setupTyCode'] = 5;
            break;
          default:
            this.popup.submitItem['setupTyCode'] = 1;
        }
      }
      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = '';
      });
      this.popup.submitItem.setupTyCode = 1;
      this.fileIdx = 1;
    },
    async deleteSubmit() {
      if (!this.tabItems[0].selectedItem.sysSetupSn.value) {
        const target = this.getPostWord(
          this.$i18n.t('operation.system.target-system'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }
      this.$dialogs
        .confirm(
          `<p>${this.$i18n.t(
            'operation.system.target-name',
          )} : <span class="c-orange">${
            this.tabItems[0].selectedItem.setupNm.value
          }</span> (${this.$i18n.t('operation.system.target-type')} : ${
            this.tabItems[0].selectedItem.setupTyName.value
          })</p>${this.$i18n.t('common.alert.delete-confirm')}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            await this.$dialogs.password().then(async ({ ok }) => {
              if (ok) {
                const reqObj = {
                  sysSetupSn: this.tabItems[0].selectedItem.sysSetupSn.value,
                  fileSn: this.fileList.fileId,
                };
                await deleteSysSet(reqObj).then(({ data }) => {
                  if (data.valid) {
                    this.$dialogs
                      .alert(this.$i18n.t('common.alert.delete-success'))
                      .then(() => {
                        this.closePopup();
                        this.clearTabItems();
                        this.reloadToggle = !this.reloadToggle;
                      });
                  } else {
                    this.$dialogs.alert(
                      data.message
                        ? data.message
                        : this.$i18n.t('common.alert.delete-fail'),
                    );
                  }
                });
              }
            });
          }
        });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    dwldUrl(fileId, fileOdrg) {
      if (fileId !== '-') {
        window.location.href = `${process.env.VUE_APP_URL_MAS_API}/operation/system/fileDwld/${this.tabItems[0].selectedItem.sysSetupSn.value}/${fileId}/${fileOdrg}/?token=${this.$store.state.auth.token}`;
      }
    },
    changeFile(index) {
      document.getElementById(`ipopInsertFileNm_${index}`).value = this.$refs[
        'file_' + index
      ][0].files[0].name;
      this.popup.submitItem['file' + index] = this.$refs[
        'file_' + index
      ][0].files[0];
    },
    insertFile(index) {
      this.$refs['file_' + index][0].click();
    },
    async deleteFile(index) {
      this.$dialogs
        .confirm(
          `<p style="margin-bottom: 10px;">${this.$i18n.t(
            'operation.system.target-file',
          )} : <span class="c-orange">${
            this.fileList.fileDetailList[index].originFileNm
          }</sapn></p>${this.$i18n.t('common.alert.delete-confirm')}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            await this.$dialogs.password().then(async ({ ok }) => {
              if (ok) {
                const reqObj = {
                  fileId: this.fileList.fileId,
                  fileOdrg: this.fileList.fileDetailList[index].fileOdrg,
                };
                await deleteFile(reqObj).then(({ data }) => {
                  if (data.valid) {
                    this.$dialogs
                      .alert(this.$i18n.t('common.alert.delete-success'))
                      .then(() => {
                        document.getElementById(`u_file_${index}`).remove();
                      });
                  } else {
                    this.$dialogs.alert(
                      data.message
                        ? data.message
                        : this.$i18n.t('common.alert.delete-fail'),
                    );
                  }
                });
              }
            });
          }
        });
    },
    appendRow(event) {
      const {
        target: { value, id },
      } = event;
      if (value === '+') {
        this.fileIdx++;
      } else {
        document.getElementById(`div_${id}`).remove();
      }
    },
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      this.$dialogs.password().then(async ({ ok }) => {
        if (ok) {
          await addSysSet(this.popup.submitItem).then(res => {
            const { status, data } = res;
            if (status == 200) {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.insert-success'))
                .then(() => {
                  this.closePopup();
                  this.clearTabItems();
                  this.reloadToggle = !this.reloadToggle;
                });
            } else {
              this.$dialogs.alert(
                data.message
                  ? data.message
                  : this.$i18n.t('common.alert.insert-fail'),
              );
            }
          });
        }
      });
    },
    async updateSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      this.$dialogs.password().then(async ({ ok }) => {
        if (ok) {
          this.popup.submitItem.fileSn = this.fileList.fileId;
          await modifySysSet(this.popup.submitItem).then(res => {
            const { status, data } = res;
            if (status == 200) {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.update-success'))
                .then(() => {
                  this.closePopup();
                  this.reloadToggle = !this.reloadToggle;
                  this.clearTabItems();
                });
            } else {
              this.$dialogs.alert(
                data.message
                  ? data.message
                  : this.$i18n.t('common.alert.update-fail'),
              );
            }
          });
        }
      });
    },
    clearFileList() {
      this.fileList = {
        fileDetailList: [{ originFileNm: '-', fileId: '-' }],
      };
    },
    // 다국어 지원을 위해 추가한 메소드
    // '기타'를 제외한 타입코드명은 모두 영어이므로, '기타'만 언어 설정에 따라 변경되도록 함.
    setSetupTyName() {
      this.tableItems.forEach(row => {
        if (row.setupTyCode === '5') {
          row.setupTyName = this.$i18n.locale === 'en' ? 'Others' : '기타';
        }
      });
    },
    // 우측 패널 데이터 초기화
    clearTabItems() {
      //  선택 정보 clear
      Object.keys(this.tabItems[0].selectedItem).forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
      this.clearFileList();
    },
  },
};
</script>

<style scoped TableListComponent>
.btnAddRow {
  min-width: 24px;
  border-radius: 5px;
  vertical-align: bottom;
}

.btnDelete {
  min-width: 24px;
  border-radius: 5px;
  vertical-align: bottom;
}

#d_fileNm > a:hover,
#u_updateFile > div > a:hover {
  color: #ff4d01;
  text-decoration: underline;
}

#u_updateFile > div > a {
  vertical-align: super;
}
</style>
