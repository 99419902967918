<template>
  <!-- 관리자 권한 기본 정보 등록 창-->
  <div v-if="popShow && openPopType == 'insert'" class="pop-drag">
    <div class="popup-mask"></div>
    <div
      class="pop-content pop-ani pop-drag panel"
      style="width:400px; height:179px;"
    >
      <div class="pop-head">
        <h2>{{ $t('operation.group.insertPopUp.title') }}</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="insertPopForm">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="27%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ groupId }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="number"
                      :label="groupId"
                      :rules="{
                        required: true,
                        digits: 5,
                      }"
                      inputClass="input-24"
                      inputStyle="width:148px"
                      autocomplete="off"
                      v-model="popup.submitItem.groupId"
                      @input="groupIdDuplChk = false"
                      @keydown="inputNumberCheck"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      @click="dupliCheck('id')"
                    >
                      {{ $t('common.btn.check-dupl') }}
                    </button>
                  </td>
                </tr>
                <tr>
                  <th>{{ groupName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="groupName"
                      :rules="{
                        required: true,
                        min: 2,
                        max: 20,
                      }"
                      inputClass="input-24 w-100"
                      inputStyle="width:148px"
                      maxlength="20"
                      required
                      autocomplete="off"
                      v-model="popup.submitItem.groupName"
                      @input="groupNmDuplChk = false"
                      @keydown="inputLiteralCheck"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      @click="dupliCheck('name')"
                    >
                      {{ $t('common.btn.check-dupl') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              id="insert_submitButton"
              :value="btnRegister"
              class="bt-line30 cr-orange"
              @click="submit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { operAllowDupliCheck, insertOperAllow } from '@/api/operationApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    // tableItems: {
    //   type: Array,
    //   required: true,
    // },
    nowPerPage: {
      type: Number,
      required: true,
    },
  },
  components: {
    ValidateForm,
  },
  mixins: [CommonMixins],
  data: () => ({
    popup: {
      type: '',
      submitItem: {
        groupName: '',
        groupId: null,
      },
    },
    groupIdDuplChk: false,
    groupNmDuplChk: false,
  }),
  computed: {
    groupId() {
      return this.$i18n.t('operation.group.insertPopUp.label1');
    },
    groupName() {
      return this.$i18n.t('operation.group.insertPopUp.label2');
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    closePopup() {
      this.$emit('close', false);
      this.clearSetting();
    },
    async dupliCheck(type) {
      let groupIdOrName = '';
      let target;
      if (type == 'name') {
        groupIdOrName = this.popup.submitItem.groupName;
        target = this.groupName;
      } else if (type == 'id') {
        groupIdOrName = this.popup.submitItem.groupId;
        target = this.groupId;
      }

      if (!groupIdOrName) {
        const field = this.getPostWord(target, '을', '를');
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', { field }),
        );
        return;
      }

      const params = {
        groupIdOrName: groupIdOrName,
        type: type,
      };
      await operAllowDupliCheck(params).then(({ data }) => {
        if (!data.valid) {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.invalid-target-inUse', { target }),
          );
        } else {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.valid-target', { target }),
          );
          if (type == 'name') {
            this.groupNmDuplChk = true;
          } else {
            this.groupIdDuplChk = true;
          }
        }
      });
    },
    async submit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      if (!this.groupIdDuplChk) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-dupl', { field: this.groupId }),
        );
        return;
      }

      if (!this.groupNmDuplChk) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-dupl', { field: this.groupName }),
        );
        return;
      }

      if (this.groupNmDuplChk && this.groupIdDuplChk) {
        const pwChckResult = await this.$dialogs.password();
        if (pwChckResult.ok) {
          const params = {
            groupId: this.popup.submitItem.groupId,
            groupName: this.popup.submitItem.groupName,
          };
          await insertOperAllow(params).then(({ data }) => {
            const { valid } = data;
            if (!valid) {
              this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
              return;
            }
            this.$dialogs
              .alert(this.$i18n.t('common.alert.insert-success'))
              .then(() => {
                this.closePopup();
                const reqbody = {
                  page: 1,
                  rows: this.nowPerPage,
                  sidx: '',
                  sord: 'asc',
                  groupName: '',
                };
                this.$emit('getList', reqbody);
                this.$emit('close', false);
              });
          });
        }
      }
    },
    clearSetting() {
      this.popup.submitItem.groupName = '';
      this.popup.submitItem.groupId = '';
      this.groupIdDuplChk = false;
      this.groupNmDuplChk = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
