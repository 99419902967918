<template>
  <section>
    <two-panel
      :leftPanelWidth="'1050'"
      :rightPanelWidth="'337'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-- 트랜잭션 목록 -->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="itemsWithIndex"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :reloadToggle="reloadToggle"
          :loading="loading"
          :customSlotInfo="customSlotInfo"
          :single-select="singleSelect"
          :show-select="showSelect"
          @rowClick="rowClick"
          @getDataFromApi="searchTrnsclogList"
        >
          <!-- 검색 옵션 -->
          <template #searchOptions>
            <label class="sort-label">
              · {{ $t('operation.transaction.search-condition1') }}</label
            >
            <input
              v-model="searchOpts.userId"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width: 50px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label">
              · {{ $t('operation.transaction.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.srvcId"
              type="text"
              class="sort-input-text"
              maxlength="20"
              style="width: 50px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label">
              · {{ $t('operation.transaction.search-condition3') }}</label
            >
            <select
              v-model="searchOpts.appProtNm"
              class="sort-input-select"
              style="width:70px"
            >
              <option value="">{{ $t('common.all') }}</option>
              <option value="fhir">Fhir</option>
              <option value="openapi">Openapi</option>
            </select>
            <label class="sort-label">
              · {{ $t('operation.transaction.search-condition4') }}</label
            >
            <input
              v-model="searchOpts.rspnsCode"
              type="number"
              class="sort-input-text"
              maxlength="20"
              style="width: 50px;"
              @keyup.enter="keyUpEnter"
              @keydown="inputNumberCheck"
            />
            <label class="sort-label">
              · {{ $t('operation.transaction.search-condition5') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.requstDt"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 160px;"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keydown="inputLiteralCheck"
                  />
                  <label
                    for="findDateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>
          <!-- subHeaderItem -->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnDeleteAll"
              class="bt-line24 cr-orange"
              @click="deleteAllTrnsclog()"
            />
          </template>
          <!-- customSlot -->
          <!-- 길이 초과될 거 같은 컬럼의 경우, mouseOver 시 텍스트 표시 
              1. td-ellipsis 클래스 추가
              2. title 추가..
          -->
          <template v-slot:userId_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.userId">
              {{ item.userId || '-' }}
            </td>
          </template>
          <template v-slot:requstUri_custom="{ item }">
            <td class="text-left td-ellipsis" :title="item.requstUri">
              {{ item.requstUri || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-- 상세 정보 -->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- customSlot -->
          <template v-slot:requstUri_tab_custom="{ item }">
            <td class="td-ellipsis">
              {{ item.value || '-' }}
            </td>
          </template>
          <template v-slot:requstHder_tab_custom="{ item }">
            <td class="td-ellipsis">
              {{ item.value || '-' }}
            </td>
          </template>
          <template v-slot:requstPld_tab_custom="{ item }">
            <td class="td-ellipsis">
              {{ item.value || '-' }}
            </td>
          </template>
          <template v-slot:rspnsHder_tab_custom="{ item }">
            <td class="td-ellipsis">
              {{ item.value || '-' }}
            </td>
          </template>
          <template v-slot:rspnsPld_tab_custom="{ item }">
            <td class="td-ellipsis">
              {{ item.value || '-' }}
            </td>
          </template>
          <template #detail_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnDetail"
                  class="bt-line24 cr-gray"
                  @click="openPopup()"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray ml-1"
                  @click="deleteTrnsclog"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!-- 상세보기 팝업 -->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:90%;">
        <div class="pop-head">
          <h2>{{ $t('operation.transaction.popUp.title') }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <table class="table-style-default table-pop">
            <colgroup>
              <col width="116px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <span style="white-space: nowrap">{{
                    $t('operation.transaction.popUp.label1')
                  }}</span>
                </td>
                <td>
                  <span>{{ popup.submitItem.trnscSn || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label2') }}</td>
                <td>
                  <span>{{ popup.submitItem.userId || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label3') }}</td>
                <td>
                  <span>{{ popup.submitItem.srvcId || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label4') }}</td>
                <td>
                  <span>{{ popup.submitItem.appProtNm || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label5') }}</td>
                <td>
                  <span>{{ popup.submitItem.requstDt || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label6') }}</td>
                <td>
                  <span>{{ popup.submitItem.requstIp || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label7') }}</td>
                <td>
                  <span>{{ popup.submitItem.requstMthdNm || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label8') }}</td>
                <td>
                  <span>{{ popup.submitItem.requstUri || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label9') }}</td>
                <td>
                  <pre style="white-space: pre-wrap;">{{
                    getJSONPrettyPrint(popup.submitItem.requstHder) || '-'
                  }}</pre>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label10') }}</td>
                <td>
                  <pre style="white-space: pre-wrap;">{{
                    popup.submitItem.requstPld === ''
                      ? '-'
                      : getJSONPrettyPrint(popup.submitItem.requstPld)
                  }}</pre>
                  <input
                    v-show="popup.submitItem.requstPld === '' ? false : true"
                    type="button"
                    :value="btnDownload"
                    class="bt-line24 cr-gray"
                    @click="downloadPayload('req')"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label11') }}</td>
                <td>
                  <span>{{ popup.submitItem.rspnsDt || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label12') }}</td>
                <td>
                  <span>{{ popup.submitItem.rspnsCode || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label13') }}</td>
                <td>
                  <pre style="white-space: pre-wrap;">{{
                    getJSONPrettyPrint(popup.submitItem.rspnsHder) || '-'
                  }}</pre>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label14') }}</td>
                <td>
                  <pre style="white-space: pre-wrap;">{{
                    popup.submitItem.rspnsPld === ''
                      ? '-'
                      : getJSONPrettyPrint(popup.submitItem.rspnsPld)
                  }}</pre>
                  <input
                    v-show="popup.submitItem.rspnsPld === '' ? false : true"
                    type="button"
                    :value="btnDownload"
                    class="bt-line24 cr-gray"
                    @click="downloadPayload('res')"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label15') }}</td>
                <td>
                  <span>{{ popup.submitItem.errorCd || '-' }}</span>
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.transaction.popUp.label16') }}</td>
                <td>
                  <span>{{ popup.submitItem.errorMsg || '-' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnClose"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import {
  getTrnsclogList,
  getTrnsclogDetail,
  deleteTrnsclog,
  deleteAllTrnsclog,
} from '@/api/operationApi';

export default {
  name: 'TransactionlogPage',
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
  },
  data() {
    return {
      // 좌측 패널 테이블
      loading: false,
      reloadToggle: true,
      tableItems: [],
      // 검색 정보
      searchOpts: {
        userId: '',
        srvcId: '',
        appProtNm: '',
        rspnsCode: '',
        requstDt: {
          start: new Date(Date.now() - 518400000),
          end: new Date(),
        },
      },
      singleSelect: false,
      showSelect: true,
      // row
      nowPerPage: 30,
      totalRecords: 0,
      totalPages: 0,
      // 우측 패널 테이블
      tabOverlay: false,
      tabItems: [],
      // custom Slot
      customSlotInfo: [
        { name: 'userId', slotName: 'item.userId' },
        { name: 'requstUri', slotName: 'item.requstUri' },
      ],

      trnscSns: '',
      // 팝업
      popup: {
        show: false,
        submitItem: {
          trnscSn: '',
          userId: '',
          srvcId: '',
          appProtNm: '',
          requstDt: '',
          requstIp: '',
          requstMthdNm: '',
          requstUri: '',
          requstHder: '',
          requstPld: '',
          rspnsDt: '',
          rspnsCode: '',
          rspnsHder: '',
          rspnsPld: '',
          errorCd: '',
          errorMsg: '',
        },
      },
    };
  },
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    selected_Trnsclog() {
      return this.tabItems[0].selectedItem.trnscSn.value;
    },
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        requstDt: this.formatDate(
          new Date(this.tableItems[index].requstDt),
          'yyyy/MM/dd hh:mm:ss',
        ),
      }));
    },
    leftPanelTitle() {
      return this.$i18n.t('operation.transaction.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column1'),
          value: 'trnscSn',
          width: '9%',
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column2'),
          value: 'userId',
          width: '12%',
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column3'),
          value: 'srvcId',
          width: '13%',
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column4'),
          value: 'appProtNm',
          width: '9%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column5'),
          value: 'requstMthdNm',
          width: '11%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column6'),
          value: 'requstUri',
          width: '20%',
          sortable: false,
          align: 'left',
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column7'),
          value: 'rspnsCode',
          width: '12%',
        },
        {
          text: this.$i18n.t('operation.transaction.leftPanel.table-column8'),
          value: 'requstDt',
          width: '14%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.transaction.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'detail',
          headers: [
            {
              text: this.$i18n.t('operation.transaction.rightPanel.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('operation.transaction.rightPanel.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            trnscSn: {
              name: this.$i18n.t('operation.transaction.rightPanel.label1'),
              value: '',
            },
            userId: {
              name: this.$i18n.t('operation.transaction.rightPanel.label2'),
              value: '',
            },
            srvcId: {
              name: this.$i18n.t('operation.transaction.rightPanel.label3'),
              value: '',
            },
            appProtNm: {
              name: this.$i18n.t('operation.transaction.rightPanel.label4'),
              value: '',
            },
            requstDt: {
              name: this.$i18n.t('operation.transaction.rightPanel.label5'),
              value: '',
            },
            requstIp: {
              name: this.$i18n.t('operation.transaction.rightPanel.label6'),
              value: '',
            },
            requstMthdNm: {
              name: this.$i18n.t('operation.transaction.rightPanel.label7'),
              value: '',
            },
            requstUri: {
              name: this.$i18n.t('operation.transaction.rightPanel.label8'),
              value: '',
              isCustom: true,
            },
            requstHder: {
              name: this.$i18n.t('operation.transaction.rightPanel.label9'),
              value: '',
              isCustom: true,
            },
            requstPld: {
              name: this.$i18n.t('operation.transaction.rightPanel.label10'),
              value: '',
              isCustom: true,
            },
            rspnsDt: {
              name: this.$i18n.t('operation.transaction.rightPanel.label11'),
              value: '',
            },
            rspnsCode: {
              name: this.$i18n.t('operation.transaction.rightPanel.label12'),
              value: '',
            },
            rspnsHder: {
              name: this.$i18n.t('operation.transaction.rightPanel.label13'),
              value: '',
              isCustom: true,
            },
            rspnsPld: {
              name: this.$i18n.t('operation.transaction.rightPanel.label14'),
              value: '',
              isCustom: true,
            },
            errorCd: {
              name: this.$i18n.t('operation.transaction.rightPanel.label15'),
              value: '',
            },
            errorMsg: {
              name: this.$i18n.t('operation.transaction.rightPanel.label16'),
              value: '',
            },
          },
        },
      ];
    },
    btnDeleteAll() {
      return this.$i18n.t('common.btn.delete-all');
    },
    btnDetail() {
      return this.$i18n.t('common.btn.detail');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnDownload() {
      return this.$i18n.t('common.btn.download');
    },
    btnClose() {
      return this.$i18n.t('common.btn.close');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 트랜잭션 이력 목록 조회
    async searchTrnsclogList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        userId,
        srvcId,
        appProtNm,
        rspnsCode,
        requstDt,
      } = searchParams;

      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        userId: userId.trim(),
        srvcId: srvcId.trim(),
        appProtNm: appProtNm,
        rspnsCode: rspnsCode.trim(),
        startDateString: this.formatDate(requstDt.start, 'yyyy-MM-dd'),
        endDateString: this.formatDate(requstDt.end, 'yyyy-MM-dd'),
      };
      await getTrnsclogList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // row 클릭 시 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      const params = {
        trnscSn: item.trnscSn,
      };
      await getTrnsclogDetail(params).then(({ data }) => {
        Object.keys(this.tabItems[0].selectedItem).forEach(key => {
          if (key == 'requstDt' || key == 'rspnsDt') {
            this.tabItems[0].selectedItem[key].value = data[key]
              ? this.formatDate(new Date(data[key]), 'yyyy/MM/dd hh:mm:ss')
              : '';
          } else {
            this.tabItems[0].selectedItem[key].value = data[key];
          }
        });
      });
      this.tabOverlay = false;
    },
    // 트랜잭션 이력 삭제
    async deleteTrnsclog() {
      if (!this.selected_Trnsclog) {
        const target = this.getPostWord(
          this.$i18n.t('operation.transaction.target-transaction'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }
      this.$dialogs
        .confirm(
          `<p>${this.$i18n.t(
            'operation.transaction.target-transactionNo',
          )} : <b class="c-orange">${
            this.selected_Trnsclog
          }</b><br/>${this.$i18n.t('common.alert.delete-confirm')}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            await deleteTrnsclog({ trnscSn: this.selected_Trnsclog }).then(
              () => {
                this.$dialogs
                  .alert(this.$i18n.t('common.alert.delete-success'))
                  .then(() => {
                    //  선택 정보 clear
                    Object.keys(this.tabItems[0].selectedItem).forEach(key => {
                      this.tabItems[0].selectedItem[key].value = '';
                    });
                    // 표 reload
                    this.reloadToggle = !this.reloadToggle;
                    this.keyUpEnter();
                    this.$refs.tableListComponent.clearSelect();
                  });
              },
            );
          }
        })
        .catch(error => {
          console.log('error', error);
          this.$dialogs.alert(this.$i18n.t('common.alert.delete-fail'));
        });
    },
    async deleteAllTrnsclog() {
      this.trnscSns = this.$store.getters['transactionlog/getTrnscSns'];
      // 테이블의 check box 기준 선택된 트랜잭션이 없으면 alert
      if (this.trnscSns === '' || this.trnscSns.length === 0) {
        const target = this.getPostWord(
          this.$i18n.t('operation.transaction.target-transaction'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      this.$dialogs
        .confirm(
          `<p>${this.$i18n.t(
            'operation.transaction.target-selectCount',
          )} : <b class="c-orange">${this.trnscSns.length}</b>${this.$i18n.t(
            'common.alert.delete-confirm',
          )}`,
        )
        .then(async ({ ok }) => {
          if (ok) {
            await deleteAllTrnsclog({ trnscSns: this.trnscSns }).then(() => {
              this.$dialogs
                .alert(this.$i18n.t('common.alert.delete-success'))
                .then(() => {
                  //  선택 정보 clear
                  Object.keys(this.tabItems[0].selectedItem).forEach(key => {
                    this.tabItems[0].selectedItem[key].value = '';
                  });
                  // 표 reload
                  this.reloadToggle = !this.reloadToggle;
                  this.keyUpEnter();
                  this.$refs.tableListComponent.clearSelect();
                });
            });
          }
        })
        .catch(error => {
          console.log('error', error);
          this.$dialogs.alert(this.$i18n.t('common.alert.delete-fail'));
        });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    openPopup() {
      if (!this.selected_Trnsclog) {
        const target = this.getPostWord(
          this.$i18n.t('operation.transaction.target-transaction'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      const selectedItem = this.tabItems[0].selectedItem;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = selectedItem[key]?.value;
      });

      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = '';
      });
    },
    downloadPayload(type) {
      window.location.href = `${process.env.VUE_APP_URL_MAS_API}/operation/transactionlog/payload/${this.selected_Trnsclog}/${type}?token=${this.$store.state.auth.token}`;
    },
  },
};
</script>

<style></style>
