var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.popShow && _vm.openPopType == 'insert')?_c('div',{staticClass:"pop-drag"},[_c('div',{staticClass:"popup-mask"}),_c('div',{staticClass:"pop-content pop-ani pop-drag panel",staticStyle:{"width":"400px","height":"179px"}},[_c('div',{staticClass:"pop-head"},[_c('h2',[_vm._v(_vm._s(_vm.$t('operation.group.insertPopUp.title')))]),_c('button',{staticClass:"btn-x",on:{"click":function($event){return _vm.closePopup()}}})]),_c('div',{staticClass:"pop-body"},[_c('validation-observer',{ref:"observer"},[_c('form',{attrs:{"id":"insertPopForm"}},[_c('table',{staticClass:"table-style-default table-pop"},[_c('colgroup',[_c('col',{attrs:{"width":"27%"}}),_c('col',{attrs:{"width":"*"}})]),_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.groupId)),_c('i',{staticClass:"star"},[_vm._v("*")])]),_c('td',[_c('validate-form',{attrs:{"type":"number","label":_vm.groupId,"rules":{
                      required: true,
                      digits: 5,
                    },"inputClass":"input-24","inputStyle":"width:148px","autocomplete":"off"},on:{"input":function($event){_vm.groupIdDuplChk = false},"keydown":_vm.inputNumberCheck},model:{value:(_vm.popup.submitItem.groupId),callback:function ($$v) {_vm.$set(_vm.popup.submitItem, "groupId", $$v)},expression:"popup.submitItem.groupId"}}),_c('button',{staticClass:"bt-line24",attrs:{"type":"button"},on:{"click":function($event){return _vm.dupliCheck('id')}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.check-dupl'))+" ")])],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.groupName)),_c('i',{staticClass:"star"},[_vm._v("*")])]),_c('td',[_c('validate-form',{attrs:{"label":_vm.groupName,"rules":{
                      required: true,
                      min: 2,
                      max: 20,
                    },"inputClass":"input-24 w-100","inputStyle":"width:148px","maxlength":"20","required":"","autocomplete":"off"},on:{"input":function($event){_vm.groupNmDuplChk = false},"keydown":_vm.inputLiteralCheck},model:{value:(_vm.popup.submitItem.groupName),callback:function ($$v) {_vm.$set(_vm.popup.submitItem, "groupName", $$v)},expression:"popup.submitItem.groupName"}}),_c('button',{staticClass:"bt-line24",attrs:{"type":"button"},on:{"click":function($event){return _vm.dupliCheck('name')}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.check-dupl'))+" ")])],1)])])])]),_c('p',{staticClass:"pop-btn-group"},[_c('input',{staticClass:"bt-line30 cr-orange",attrs:{"type":"button","id":"insert_submitButton","value":_vm.btnRegister},on:{"click":function($event){return _vm.submit()}}}),_c('input',{staticClass:"bt-line30",attrs:{"type":"button","value":_vm.btnCancel},on:{"click":function($event){return _vm.closePopup()}}})])])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }