<template>
  <section>
    <!------------------------    TowPanel   ---------------------------->
    <two-panel
      :leftPanelWidth="twoPanel.leftPanelWidth"
      :rightPanelWidth="twoPanel.rightPanelWidth"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <!------------------------    왼쪽 패널 (관리자 목록 리스트)   ---------------------------->
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :tableHeight="'603'"
          :headers="headers"
          :items="itemsWithIndex"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :nowPerPage="nowPerPage"
          :loading="loading"
          :searchOpts="srvc"
          :customSlotInfo="customSlotInfo"
          @rowClick="rowClick"
          @getDataFromApi="getOptorList"
        >
          <!------------------------    검색 [아이디, 이름, 서비스명]   ---------------------------->
          <template #searchOptions>
            <label class="sort-label">
              · {{ $t('operation.operator.search-condition1') }}
            </label>
            <input
              type="text"
              class="sort-input-text"
              v-model="srvc.userId"
              maxlength="20"
              style="width:152px"
              @keyup.enter="keyUpEnter"
            />
            <label class="sort-label"
              >· {{ $t('operation.operator.search-condition2') }}</label
            >
            <input
              type="text"
              class="sort-input-text"
              v-model="srvc.userName"
              maxlength="20"
              style="width:152px"
              @keyup.enter="keyUpEnter"
            />
            <label class="sort-label"
              >· {{ $t('operation.operator.search-condition3') }}</label
            >
            <select
              class="sort-input-select"
              v-model="srvc.srvcId"
              style="width:343px"
            >
              <option value="">{{ $t('common.all') }}</option>
              <option
                v-for="(item, index) in srvcList.srvcNm"
                :key="index"
                :value="srvcList.srvcId[index]"
              >
                {{ item }}
              </option>
            </select>
          </template>
          <!------------------------    관리자 등록 버튼   ---------------------------->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
          <!-- 길이 초과 컬럼의 경우, title bind. mouseover 시 텍스트 표시 -->
          <template v-slot:userName_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.userName">
              {{ item.userName }}
            </td>
          </template>
          <template v-slot:srvcNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.srvcNm">
              {{ item.srvcNm }}
            </td>
          </template>
          <template v-slot:email_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.email">
              {{ item.email }}
            </td>
          </template>
          <template v-slot:userId_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.userId">
              {{ item.userId }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!------------------------    오른쪽 패널    ---------------------------->
      <template #rightPanel>
        <!------------------------    선택한 관리자 상세보기 TAB   ---------------------------->
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'290'"
        >
          <template v-slot:loginFailed_tab_custom="{ item }">
            <td :style="{ color: getLoginFailedColor(item.value) }">
              {{ item.value }}
            </td>
          </template>
          <!------------------------    선택한 관리자 관리 버튼  ---------------------------->
          <template #detail_bottom_button>
            <div class="table-bot-btns" style="border-bottom: 0px;">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdatePw"
                  class="bt-line24 cr-gray"
                  @click="openPopup('changePw')"
                />
                <input
                  v-if="
                    tabItems[0].selectedItem.loginFailed.value ==
                      $t('operation.operator.rightPanel.tab1.label-lock')
                  "
                  type="button"
                  :value="btnUnlock"
                  class="bt-line24 cr-gray ml-1"
                  @click="openPopup('unLock')"
                />
                <input
                  v-else
                  type="button"
                  :value="btnLock"
                  class="bt-line24 cr-gray ml-1"
                  @click="openPopup('unLock')"
                />

                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray ml-1"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  :value="btnDelete"
                  class="bt-line24 cr-gray ml-1"
                  @click="openPopup('delete')"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
        <!------------------------    선택한 관리자 IP 관리  ---------------------------->
        <table class="table-style-default">
          <tbody>
            <tr>
              <td style="padding: 0px; border-bottom: unset!important">
                <div class="panel">
                  <div class="panel-top-title">
                    <h4>
                      {{ $t('operation.operator.rightPanel.tab2.title') }}
                    </h4>
                    <!-- <h4>허용 IP 목록</h4> -->
                    <p class="panel-top-btns">
                      <input
                        type="button"
                        :value="btnAdd"
                        id="btnAddNewIpPop"
                        class="bt-line24 cr-orange"
                        @click="rowAppend()"
                      />
                    </p>
                  </div>
                  <div class="panel-body" id="ipGridPanelPop">
                    <v-data-table
                      dense
                      :headers="insertIpHeaders"
                      :items="ipListWithIndex"
                      :items-per-page="100"
                      :height="'354'"
                      hide-default-footer
                      class="elevation-1 text-center"
                    >
                      <template v-slot:[`item.index`]="{ item }">
                        <td
                          class="text-center table-index"
                          :title="`${item.index}`"
                        >
                          {{ item.index }}
                        </td>
                      </template>
                      <template v-slot:[`item.ip`]="{ item }">
                        <td
                          class="text-center"
                          :title="`${item.ip}`"
                          @click="ipRowClick(item.index)"
                        >
                          {{ item.ip }}
                        </td>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <td class="text-center">
                          <v-icon
                            :size="21"
                            color="indigo darken-2"
                            @click="ipRowsDel(item.index)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </template>
                      <template v-slot:no-data>
                        {{ $t('common.pagination.label-view-noData') }}
                      </template>
                    </v-data-table>
                    <div
                      class="table-bot-btns table-bot-btns-noline"
                      style="border-top: 1px solid #ccc; background-color:white; border-bottom:unset!important"
                    >
                      <P class="f-right">
                        <input
                          type="button"
                          id="button_insert_ip"
                          :value="btnApply"
                          class="bt-line24 cr-gray"
                          @click="updateIp()"
                        />
                      </P>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </two-panel>
    <!------------------------    관리자 관리 등록 팝업  ---------------------------->
    <operator-insert-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :srvcList="srvcList"
      :nowPerPage="nowPerPage"
      :authGroup="authGroup"
      @close="result => (popShow = result)"
      @getOperatorList="getOptorList"
    >
    </operator-insert-pop-up>

    <!------------------------    비밀번호 변경 팝업 팝업  ---------------------------->
    <operator-chpw-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :clickUserId="clickUserId"
      @close="result => (popShow = result)"
    >
    </operator-chpw-pop-up>

    <!------------------------    관리자 수정 팝업  ---------------------------->
    <operator-update-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :itemList="clickItems"
      :nowPerPage="nowPerPage"
      :authGroup="authGroup"
      :clickUserId="clickUserId"
      @close="result => (popShow = result)"
      @getOperatorList="getOptorList"
      @getOperDetail="rowClick"
    >
    </operator-update-pop-up>
  </section>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent.vue';
import TableTabComponent from '@/components/TableTabComponent.vue';
import OperatorInsertPopUp from './OperatorInsertPopUp';
import OperatorChpwPopUp from './OperatorChPwPopUp';
import OperatorUpdatePopUp from './OperatorUpdatePopUp';
import {
  getOperatorList,
  getOperatorSrvcList,
  getOperatorDetail,
  updateLockOperator,
  getAuthorities,
  delOperator,
  updateOperAllowIp,
} from '@/api/operationApi';

export default {
  mixins: [CommonMixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    OperatorInsertPopUp,
    OperatorChpwPopUp,
    OperatorUpdatePopUp,
  },
  data: () => ({
    loading: false,
    // panel width 값
    twoPanel: {
      leftPanelWidth: '1050',
      rightPanelWidth: '337',
    },
    // --- custom Slot --- //
    customSlotInfo: [
      { name: 'userName', slotName: 'item.userName' },
      { name: 'srvcNm', slotName: 'item.srvcNm' },
      { name: 'email', slotName: 'item.email' },
      { name: 'userId', slotName: 'item.userId' },
    ],
    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    srvc: {
      userId: '',
      userName: '',
      srvcId: '',
    },
    srvcList: {
      srvcNm: '',
      srvcId: '',
    },
    openPopType: '',
    popShow: false,
    clickUserId: '',
    clickUserLoginFailed: 0,
    clickItems: {},
    authGroup: {},

    // --- ip --- //
    // insertIpHeader
    ipList: [],
    ipv4Regex: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  }),
  methods: {
    // ip 수정
    async updateIp() {
      const result = await this.$dialogs.confirm(
        this.$i18n.t('common.alert.update-ip-confirm'),
      );
      if (result.ok) {
        //ip 유효성 검증
        let ipArr = [];
        for (let i = 0; i < this.ipList.length; i++) {
          // 입력값 검증
          if (this.ipList.length < 1) {
            return;
          }
          if (
            this.ipList[i].ip == '' ||
            (this.ipList[i].ip != '*' &&
              !this.ipv4Regex.test(this.ipList[i].ip))
          ) {
            this.$dialogs.alert(
              this.$i18n.t('common.alert.invalid-ip', {
                ip: this.ipList[i].ip,
              }),
            );
            return;
          }
          ipArr.push(this.ipList[i].ip);
        }

        const params = {
          allowIps: ipArr,
          userId: this.clickUserId,
        };
        await updateOperAllowIp(params)
          .then(({ data }) => {
            if (data.valid) {
              this.$dialogs.alert(this.$i18n.t('common.alert.update-success'));
            }
          })
          .catch(error => {
            // 에러처리?
            if (error.response.status === 409) {
              this.$dialogs.alert(error.response.data.message);
            }
            console.log('error', error);
          });
        // 409에러 catch
      }
    },
    // ip row del
    ipRowsDel(e) {
      this.ipList.splice(e - 1, 1);
    },
    // ip row 클릭시 팝업 오픈
    async ipRowClick(index) {
      const insertResult = await this.$dialogs.ipInsert();
      if (insertResult.ok) {
        this.$store.getters['operator/getIp'];
        this.ipList[index - 1].ip = this.$store.getters['operator/getIp'];
        this.ipList[index - 1].actions = '';
        this.$store.commit('operator/setIp', '');
      }
    },
    // ip row 추가
    rowAppend() {
      if (!this.clickUserId) {
        const target = this.getPostWord(
          this.$i18n.t('operation.operator.target-operator'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      const newItem = { ip: '' };
      this.ipList.push(newItem);
    },
    // 관리자 리스트 조회
    async getOptorList(searchParams) {
      this.loading = true;
      this.tabItemsClear();
      const { rows, page, sord, sidx, userId, userName, srvcId } = searchParams;

      const params = {
        rows,
        page,
        sord,
        sidx: sidx == 'mobileNo' ? 'mobileNo_text' : sidx,
        userId,
        userName,
        srvcId,
      };

      await getOperatorList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;

          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
          this.loading = false;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    // 관리자 서비스 목록 리스트 조회
    async getOptorSrvcList() {
      this.loading = true;

      await getOperatorSrvcList()
        .then(({ data }) => {
          const srvcNmList = data.map(data => data.srvcNm);
          const srvcIdList = data.map(data => data.srvcId);
          this.srvcList.srvcId = srvcIdList;
          this.srvcList.srvcNm = srvcNmList;
          this.loading = false;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    // 상세정보 서비스 값 format
    getServiceTxt(data) {
      let userServices = '';
      if (data && data.length > 0) {
        data.forEach(srvcVal => {
          userServices += srvcVal.srvcNm + '(' + srvcVal.srvcId + ')</br>';
        });
      }
      userServices =
        '<div style="height:60px; overflow-y:auto;">' + userServices;
      return userServices;
    },
    async rowClick(item) {
      //  ip 리스트 초기화
      this.ipList = [];
      this.tabOverlay = true;
      this.clickUserId = item.userId;
      const params = {
        userId: item.userId,
      };
      await getOperatorDetail(params)
        .then(({ data }) => {
          this.clickItems = data;
          for (let i = 0; i < data.allowIps.length; i++) {
            this.ipList.push({ ip: data.allowIps[i] });
          }
          this.clickUserLoginFailed = data.loginFailed;
          const keys = Object.keys(this.tabItems[0].selectedItem);
          keys.forEach(key => {
            let detailVal;
            switch (key) {
              case 'userId':
                detailVal = data[key];
                break;
              case 'userName':
                detailVal = data[key] ? data[key] : '';
                break;
              case 'mobileNo':
                detailVal = this.getPhoneNoText(data[key]);
                break;
              case 'email':
                detailVal = data[key] ? data[key] : '';
                break;
              case 'userGroupNo':
                detailVal = this.authGroup[data[key]];
                break;
              case 'srvcId':
                detailVal = data[key]
                  ? data['srvcNm'] + '(' + data[key] + ')'
                  : '';
                break;
              case 'loginFailed':
                detailVal =
                  data[key] < 5 && data[key] >= 0
                    ? this.$i18n.t(
                        'operation.operator.rightPanel.tab1.label-unlock',
                      )
                    : data[key] >= 5
                    ? this.$i18n.t(
                        'operation.operator.rightPanel.tab1.label-lock',
                      )
                    : null;
                break;
              case 'lastLoginIp':
                detailVal = data[key];
                break;
              case 'lastLoginDate':
                detailVal = data[key]
                  ? this.formatDate(new Date(data[key]), 'yyyy/MM/dd hh:mm:ss')
                  : '';
                break;
              default:
                detailVal = data[key];
            }
            this.tabItems[0].selectedItem[key].value = detailVal;
          });
          this.tabOverlay = false;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    keyUpEnter() {
      this.clickUserId = '';
      this.$refs.tableListComponent.search();
    },
    // 관리자 잠김 여부 색상
    getLoginFailedColor(value) {
      let color;
      color =
        value ===
        this.$i18n.t('operation.operator.rightPanel.tab1.label-unlock')
          ? '#333333'
          : value ===
            this.$i18n.t('operation.operator.rightPanel.tab1.label-lock')
          ? '#F25555'
          : null;
      return color;
    },
    async openPopup(type) {
      this.openPopType = type;
      if (
        type == 'changePw' ||
        type == 'unLock' ||
        type == 'update' ||
        type == 'delete'
      ) {
        if (!this.clickUserId) {
          const target = this.getPostWord(
            this.$i18n.t('operation.operator.target-operator'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-select', { target }),
          );
          return;
        }
        if (type == 'unLock') {
          if (this.clickUserId == sessionStorage.getItem('userId')) {
            this.$dialogs.alert(this.$i18n.t('common.alert.lock-fail'));
            return;
          }

          let message;

          if (this.clickUserLoginFailed >= 5) {
            message = this.$i18n.t('common.alert.unlock-confirm');
          } else {
            message = this.$i18n.t('common.alert.lock-confirm');
          }
          const result = await this.$dialogs.confirm(
            `<p>${this.$i18n.t('operation.operator.target-operator')} : <span>${
              this.tabItems[0].selectedItem.userName.value
            }</span> (<span class="c-orange">${
              this.clickUserId
            }</span>)</p><p>${message}</p>`,
          );
          if (result.ok) {
            const params = {
              loginFailed: 0,
              userId: this.clickUserId,
            };
            let successMessage = '';
            let lock = '';
            if (this.clickUserLoginFailed >= 5) {
              successMessage = this.$i18n.t('common.alert.unlock-success');
              this.clickUserLoginFailed = 0;
              lock = this.$i18n.t(
                'operation.operator.rightPanel.tab1.label-unlock',
              );
            } else {
              params.loginFailed = 10;
              successMessage = this.$i18n.t('common.alert.lock-success');
              this.clickUserLoginFailed = 10;
              lock = this.$i18n.t(
                'operation.operator.rightPanel.tab1.label-lock',
              );
            }
            await updateLockOperator(params).then(() => {
              this.$dialogs.alert(successMessage);
              this.tabItems[0].selectedItem.loginFailed.value = lock;
            });
          }
        }
        if (type == 'delete') {
          if (this.clickUserId == sessionStorage.getItem('userId')) {
            this.$dialogs.alert(this.$i18n.t('common.alert.lock-fail'));
            return;
          }
          const result = await this.$dialogs.confirm(
            `<p>${this.$i18n.t('operation.operator.target-operator')} : <span>${
              this.tabItems[0].selectedItem.userName.value
            }</span> (<span class="c-orange">${
              this.clickUserId
            }</span>)</p>${this.$i18n.t('common.alert.delete-confirm')}`,
          );
          if (result.ok) {
            const passwordChk = await this.$dialogs.password();
            if (passwordChk.ok) {
              try {
                const param = {
                  userId: this.clickUserId,
                };
                await delOperator(param).then(({ data }) => {
                  if (data.valid) {
                    this.$dialogs
                      .alert(this.$i18n.t('common.alert.delete-success'))
                      .then(() => {
                        const reqbody = {
                          page: 1,
                          rows: this.nowPerPage,
                          sidx: null,
                          sord: 'asc',
                          userId: '',
                          userName: '',
                          srvcId: '',
                        };
                        this.getOptorList(reqbody);
                      })
                      .finally(() => {
                        this.tabItemsClear();
                      });
                  }
                });
              } catch (error) {
                console.log('error', error);
              }
            }
          }
        }
      }
      this.popShow = true;
    },
    // 관리자 권한 목록 조회
    async getAuthList() {
      await getAuthorities().then(({ data }) => {
        let dataObj = {};
        for (let i = 0; i < data.length; i++) {
          dataObj[data[i].groupId] = data[i].groupName;
        }
        this.authGroup = dataObj;
      });
    },
    tabItemsClear() {
      const keys = Object.keys(this.tabItems[0].selectedItem);
      keys.forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
    },
  },
  created() {
    this.getOptorSrvcList();
    this.getAuthList();
  },
  computed: {
    // 관리자 리스트
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        userGroupNo: this.authGroup[this.tableItems[index].userGroupNo],
        srvcNm: this.tableItems[index].srvcNm
          ? this.tableItems[index].srvcNm +
            '(' +
            this.tableItems[index].srvcId +
            ')'
          : '',
        //   getPhoneNoText
        mobileNo: this.tableItems[index].mobileNo
          ? this.getPhoneNoText(this.tableItems[index].mobileNo)
          : null,
        lastLoginDate: this.tableItems[index].lastLoginDate
          ? this.formatDate(
              new Date(this.tableItems[index].lastLoginDate),
              'yyyy/MM/dd hh:mm:ss',
            )
          : '',
      }));
    },
    // ip 리스트 인덱스
    ipListWithIndex() {
      return this.ipList.map((ipList, index) => ({
        ...ipList,
        index: index + 1,
      }));
    },
    leftPanelTitle() {
      return this.$i18n.t('operation.operator.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column1'),
          value: 'userId',
          width: '10%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column2'),
          value: 'userName',
          width: '10%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column3'),
          value: 'mobileNo',
          width: '12%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column4'),
          value: 'email',
          width: '11%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column5'),
          value: 'userGroupNo',
          width: '12%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column6'),
          value: 'srvcNm',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column7'),
          value: 'lastLoginIp',
          width: '15%',
        },
        {
          text: this.$i18n.t('operation.operator.leftPanel.table-column8'),
          value: 'lastLoginDate',
          width: '15%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.operator.rightPanel.tab1.title');
    },
    tabItems() {
      return [
        {
          id: 'detail',
          headers: [
            {
              text: this.$i18n.t('operation.operator.rightPanel.tab1.header1'),
              width: '40%',
            },
            {
              text: this.$i18n.t('operation.operator.rightPanel.tab1.header2'),
              width: '60%',
            },
          ],
          selectedItem: {
            userId: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label1'),
              value: '',
            },
            userName: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label2'),
              value: '',
            },
            mobileNo: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label3'),
              value: '',
            },
            email: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label4'),
              value: '',
            },
            userGroupNo: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label5'),
              value: '',
            },
            srvcId: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label6'),
              value: '',
            },
            loginFailed: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label7'),
              value: '',
              isCustom: true,
            },
            lastLoginIp: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label8'),
              value: '',
            },
            lastLoginDate: {
              name: this.$i18n.t('operation.operator.rightPanel.tab1.label9'),
              value: '',
            },
          },
        },
      ];
    },
    insertIpHeaders() {
      return [
        {
          text: '',
          value: 'index',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.operator.rightPanel.tab2.header1'),
          value: 'ip',
          width: '70%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.operator.rightPanel.tab2.header2'),
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ];
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdatePw() {
      return this.$i18n.t('common.btn.update-pw');
    },
    btnLock() {
      return this.$i18n.t('common.btn.account-lock');
    },
    btnUnlock() {
      return this.$i18n.t('common.btn.account-unlock');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnAdd() {
      return this.$i18n.t('common.btn.add');
    },
    btnApply() {
      return this.$i18n.t('common.btn.apply');
    },
  },
};
</script>

<style lang="scss" scoped></style>
