<template>
  <section>
    <two-panel
      :leftPanelWidth="'820'"
      :rightPanelWidth="'567'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <!-------------- 질의응답 목록 -------------->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :customSlotInfo="customSlotInfo"
          :reloadToggle="reloadToggle"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="getQnaSrchList"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('operation.qna.search-condition1') }}</label
            >
            <input
              v-model="searchOpts.questSj"
              type="text"
              class="sort-input-text"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('operation.qna.search-condition2') }}</label
            >
            <input
              v-model="searchOpts.qstnerNm"
              type="text"
              class="sort-input-text"
              maxlength="30"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />
            <label class="sort-label"
              >· {{ $t('operation.qna.search-condition3') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.dateRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                    @keydown="inputLiteralCheck"
                  />
                  <label
                    for="findDateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>

          <!-- customSlot -->
          <template v-slot:unRspns_custom="{ item }">
            <td
              class="text-center"
              :style="{ color: getUnRspnsColor(item.unRspns) }"
            >
              {{ getUnRspnsTxt(item.unRspns) }}
            </td>
          </template>
          <!-- 길이 초과될 거 같은 컬럼의 경우, mouseOver 시 텍스트 표시 
              1. td-ellipsis 클래스 추가
              2. title 추가..
          -->
          <template v-slot:questSj_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.questSj">
              {{ item.questSj || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <!-------------- 상세 정보 -------------->
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot -->
          <template v-slot:questCn_tab_custom="{ item }">
            <td style="padding: 2px 7px 2px 7px;">
              <textarea
                style="height:200px; overflow-y:auto; color:black;"
                class="input-24 w-100"
                :value="item.value || '-'"
                disabled
              >
              </textarea>
            </td>
          </template>
          <template v-slot:rspnsCn_tab_custom="{ item }">
            <td style="padding: 2px 7px 2px 7px;">
              <textarea
                style="height:200px; overflow-y:auto; color:black;"
                class="input-24 w-100"
                :value="item.value || '-'"
                disabled
              >
              </textarea>
            </td>
          </template>

          <!-- bottom에 버튼 -->
          <template #qnaInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  id="button_update_app"
                  :value="btnResponse"
                  class="bt-line24 cr-gray"
                  @click="openPopup"
                />
                <input
                  type="button"
                  id="button_delete_app"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="goDeleteQna"
                />
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!---------------------------- 질의응답관리 답변/수정 팝업 ---------------------------->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ $t('operation.qna.popUp.title') }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <validation-observer ref="observer">
            <form>
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="125px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t('operation.qna.popUp.label1') }}</th>
                    <td>
                      {{ popup.qnaItem.questSj }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('operation.qna.popUp.label2') }}</th>
                    <td>
                      <textarea
                        class="input-24 w-100"
                        style="height:200px; overflow-y:auto;"
                        v-model="popup.qnaItem.questCn"
                        readonly
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ responseContent }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="responseContent"
                        v-model="popup.qnaItem.rspnsCn"
                        name="rspnsCn"
                        :rules="{ required: true, max: 1500 }"
                        maxlength="1500"
                        inputClass="input-24 w-100"
                        inputStyle="height:200px; overflow-y:auto;"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <p class="pop-btn-group">
              <input
                type="button"
                :value="btnSubmit"
                class="bt-line30 cr-orange"
                @click="goUpdateQna"
              />
              <input
                type="button"
                :value="btnCancel"
                class="bt-line30"
                @click="closePopup"
              />
            </p>
          </validation-observer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import {
  getQnaList,
  getQnaDetail,
  updateQna,
  deleteQna,
} from '@/api/operationApi';
import ValidateForm from '@/components/ValidateForm.vue';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    ValidateForm,
  },
  data: () => ({
    // --- 테이블 --- //
    loading: false,
    // --- 질의응답 관리 검색 정보 --- //
    searchOpts: {
      questSj: '',
      qstnerNm: '',
      dateRange: '',
      // sidx: null,
      // sord: null,
    },
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    reloadToggle: true,
    // --- custom Slot --- //
    customSlotInfo: [
      { name: 'unRspns', slotName: 'item.unRspns' },
      { name: 'questSj', slotName: 'item.questSj' },
      { name: 'questCn', slotName: 'item.questCn' },
      { name: 'rspnsCn', slotName: 'item.rspnsCn' },
    ],
    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
    tabItems: [],
    // 팝업
    popup: {
      show: false,
      qnaItem: { questNo: '', questSj: '', questCn: '', rspnsCn: '' },
    },
  }),
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('operation.qna.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.qna.leftPanel.table-column1'),
          value: 'questSj',
          width: '50%',
        },
        {
          text: this.$i18n.t('operation.qna.leftPanel.table-column2'),
          value: 'qstnerNm',
          width: '18%',
        },
        {
          text: this.$i18n.t('operation.qna.leftPanel.table-column3'),
          value: 'unRspns',
          width: '12%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.qna.leftPanel.table-column4'),
          value: 'questDt',
          width: '20%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.qna.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'qnaInfo',
          headers: [
            {
              text: this.$i18n.t('operation.qna.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('operation.qna.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            questNo: {
              name: this.$i18n.t('operation.qna.rightPanel.label1'),
              value: '',
            },
            questSj: {
              name: this.$i18n.t('operation.qna.rightPanel.label2'),
              value: '',
            },
            questCn: {
              name: this.$i18n.t('operation.qna.rightPanel.label3'),
              value: '',
              isCustom: true,
            },
            rspnsCn: {
              name: this.$i18n.t('operation.qna.rightPanel.label4'),
              value: '',
              isCustom: true,
            },
            qstnerNm: {
              name: this.$i18n.t('operation.qna.rightPanel.label5'),
              value: '',
            },
            questDt: {
              name: this.$i18n.t('operation.qna.rightPanel.label6'),
              value: '',
            },
            respondId: {
              name: this.$i18n.t('operation.qna.rightPanel.label7'),
              value: '',
            },
            rspnsDt: {
              name: this.$i18n.t('operation.qna.rightPanel.label8'),
              value: '',
            },
          },
        },
      ];
    },
    responseContent() {
      return this.$i18n.t('operation.qna.popUp.label3');
    },
    btnResponse() {
      return this.$i18n.t('common.btn.response');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 질의응답관리 목록 조회
    async getQnaSrchList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        questSj,
        qstnerNm,
        dateRange,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        dateRange,
        questSj: questSj.trim(),
        qstnerNm: qstnerNm.trim(),
        startDateString: dateRange.start,
        endDateString: dateRange.end,
      };
      await getQnaList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    getUnRspnsTxt(unRspns) {
      if (unRspns === 'Y')
        return this.$i18n.t('operation.qna.leftPanel.label-responseN');
      else if (unRspns === 'N')
        return this.$i18n.t('operation.qna.leftPanel.label-responseY');
    },
    getUnRspnsColor(unRspns) {
      if (unRspns === 'Y') return '#F25555';
    },
    // row 클릭 시 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getQnaDetail(item.questNo).then(({ data }) => {
        const keys = Object.keys(this.tabItems[0].selectedItem);
        keys.forEach(key => {
          this.tabItems[0].selectedItem[key].value = data.detail[key];
        });
      });
      this.tabOverlay = false;
    },

    // 팝업
    openPopup() {
      const selectedItem = this.tabItems[0].selectedItem;
      if (!selectedItem.questNo.value) {
        const target = this.getPostWord(
          this.$i18n.t('operation.qna.target-qna'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', { target }),
        );
        return;
      }
      Object.keys(this.popup.qnaItem).forEach(key => {
        this.popup.qnaItem[key] = selectedItem[key]?.value;
      });
      this.popup.show = true;
    },

    // 질의응답 답변/수정
    async goUpdateQna() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      // 선택된 row의 questNo
      const questNo = this.tabItems[0].selectedItem.questNo.value;
      await updateQna(this.popup.qnaItem).then(({ data }) => {
        try {
          if (data.valid) {
            this.$dialogs
              .alert(this.$i18n.t('common.alert.insert-success'))
              .then(() => {
                this.closePopup();
                this.reloadToggle = !this.reloadToggle;
                this.rowClick({ questNo });
              });
          } else {
            this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
          }
        } catch (error) {
          console.log('error', error);
        }
      });
    },

    // 질의응답 삭제
    async goDeleteQna() {
      const questNo = this.tabItems[0].selectedItem.questNo.value;
      if (!questNo) {
        const target = this.getPostWord(
          this.$i18n.t('operation.qna.target-qna'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }

      this.$dialogs
        .confirm(this.$i18n.t('common.alert.delete-confirm'))
        .then(async ({ ok }) => {
          if (ok) {
            try {
              await deleteQna(questNo).then(({ data }) => {
                if (data.valid) {
                  this.$dialogs.alert(
                    this.$i18n.t('common.alert.delete-success'),
                  );
                  this.closePopup();
                  Object.keys(this.tabItems[0].selectedItem).forEach(key => {
                    this.tabItems[0].selectedItem[key].value = '';
                  });
                  this.reloadToggle = !this.reloadToggle;
                } else {
                  this.$dialogs.alert(this.$i18n.t('common.alert.delete-fail'));
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        });
    },

    // 팝업 닫기
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.qnaItem).forEach(key => {
        this.popup.qnaItem[key] = '';
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
