<template>
  <section>
    <three-panel
      :topPanelTitle="topPanelTitle"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #topPanel>
        <div class="panel-body">
          <table-list-component
            ref="tableListComponent"
            :tableHeight="'238'"
            :headers="headers"
            :loading="loading"
            :searchOpts="srchOpt"
            :items="itemsWithIndex"
            :nowPerPage="nowPerPage"
            :totalRecords="totalRecords"
            :totalPages="totalPages"
            :customSlotInfo="customSlotInfo"
            @getDataFromApi="getAuthList"
            @rowClick="rowClick"
          >
            <template v-slot:actions_custom="{ item }">
              <td class="text-center" :title="item.actions">
                <v-icon
                  :size="21"
                  color="indigo darken-2"
                  @click="allowRowsDel(item)"
                >
                  mdi-delete
                </v-icon>
              </td>
            </template>
            <template #searchOptions>
              <label class="sort-label"
                >· {{ $t('operation.group.search-condition1') }}</label
              >
              <input
                v-model="srchOpt.groupName"
                type="text"
                class="sort-input-text"
                maxlength="30"
                @keyup.enter="keyUpEnter"
                @keydown="inputLiteralCheck"
              />
            </template>
            <!------------------------    관리자 권한 등록 버튼   ---------------------------->
            <template #subHeaderItem>
              <input
                type="button"
                :value="btnRegister"
                class="bt-line24 cr-orange"
                @click="openPopup('insert')"
              />
            </template>
          </table-list-component>
        </div>
      </template>
      <template #leftPanelBtn>
        <div id="detailPanel" class="panel">
          <p class="panel-top-btns">
            <input
              type="button"
              :value="btnUpdate"
              class="bt-line24"
              @click="openPopup('update')"
            />
          </p>
        </div>
      </template>
      <template #leftPanel>
        <!-- overlay -->
        <v-overlay absolute="absolute" :value="tabOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-body" style="height: 331px">
          <table class="table-style-default first-bg">
            <colgroup>
              <col width="110px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td>{{ $t('operation.group.leftPanel.label1') }}</td>
                <td>
                  {{ clickedItem.groupId }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.group.leftPanel.label2') }}</td>
                <td>
                  {{ clickedItem.groupName }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.group.leftPanel.label3') }}</td>
                <td>
                  {{ clickedItem.createDate }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('operation.group.leftPanel.label4') }}</td>
                <td>
                  {{ clickedItem.createrId }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #rightPanelBtn>
        <input
          type="button"
          :value="btnUpdate"
          class="bt-line24"
          @click="allowUpdate"
        />
      </template>
      <template #rightPanel>
        <v-overlay absolute="absolute" :value="tabOverlay">
          <v-progress-circular
            indeterminate
            size="50"
            color="deep-orange lighten-2"
          ></v-progress-circular>
        </v-overlay>
        <div class="panel-body" style="height:330px ">
          <v-container fluid class="pa-0">
            <v-data-table
              dense
              :fixed-header="true"
              :height="'330'"
              :headers="menusHeaders"
              :items="clickedItem.menus"
              :items-per-page="200"
              class="text-center"
              hide-default-footer
              :mobile-breakpoint="0"
            >
              <template v-slot:[`item.subMenuName`]="{ item }">
                <td class="text-center">
                  {{ item.subMenuName }}
                </td>
              </template>
              <template v-slot:[`item.mainMenuName`]="{ item }">
                <td v-if="`${item.span}` > 0" :rowspan="`${item.span}`">
                  {{ item.mainMenuName }}
                </td>
                <td v-else style="display:none">{{ item.mainMenuName }}</td>
              </template>
              <template v-slot:[`item.groupMenuId`]="{ item }">
                <v-checkbox
                  class="text-center"
                  style="margin-left: 140px;"
                  dense
                  :ripple="false"
                  color="blue"
                  :size="12"
                  v-model="useSelectedList"
                  :value="`${item.subMenuId}`"
                >
                </v-checkbox>
              </template>
              <!-- no data -->
              <template v-slot:no-data>
                {{ $t('operation.group.rightPanel.desc') }}
              </template>
            </v-data-table>
          </v-container>
        </div>
      </template>
    </three-panel>
    <!------------------------    관리자 권한 등록 팝업  ---------------------------->
    <operator-group-insert-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :nowPerPage="nowPerPage"
      @close="result => (popShow = result)"
      @getList="getAuthList"
    >
    </operator-group-insert-pop-up>

    <operator-group-update-pop-up
      :openPopType="openPopType"
      :popShow="popShow"
      :nowPerPage="nowPerPage"
      :clickedItem="clickedItem"
      @close="result => (popShow = result)"
      @getList="getAuthList"
      @updateItem="
        item => (
          (this.clickedItem.groupId = item.groupId),
          (this.clickedItem.createDate = item.createDate),
          (this.clickedItem.createrId = item.createrId),
          (this.clickedItem.groupName = item.groupName),
          (this.clickedItem.menus = item.menus)
        )
      "
    >
      <!-- _.merge(this.clickedItem, item) -->
    </operator-group-update-pop-up>
  </section>
</template>

<script>
import ThreePanel from '@/layouts/ThreePanel.vue';
import mixins from '@/mixins/CommonMixins';
import TableListComponent from '@/components/TableListComponent';
import OperatorGroupInsertPopUp from './OperatorGroupInsertPopUp';
import OperatorGroupUpdatePopUp from './OperatorGroupUpdatePopUp';

import {
  getAuthoritiesList,
  deleteOperAllow,
  detailOperAllow,
  updateOperAllowMenu,
} from '@/api/operationApi';

export default {
  mixins: [mixins],
  components: {
    ThreePanel,
    TableListComponent,
    OperatorGroupInsertPopUp,
    OperatorGroupUpdatePopUp,
  },
  data: () => ({
    tabOverlay: false,
    loading: false,
    customSlotInfo: [{ name: 'actions', slotName: 'item.actions' }],
    srchOpt: {
      groupName: '',
    },
    authArr: [],
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 10,
    clickedItem: {
      createDate: null,
      createrId: '',
      groupId: '',
      groupName: '',
      menus: [],
    },
    useSelectedList: [],
    clickGroupId: '',
    openPopType: '',
    popShow: false,
  }),
  methods: {
    // 관리자 권한 목록 조회
    async getAuthList(searchParams) {
      this.loading = true;
      const { rows, page, groupName } = searchParams;
      const params = {
        rows,
        page,
        sord: 'asc',
        sidx: '',
        groupName,
      };
      await getAuthoritiesList(params).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    async rowClick(items) {
      this.tabOverlay = true;
      this.useSelectedList = [];
      this.clickGroupId = items.groupId;
      await detailOperAllow({ groupId: items.groupId }).then(({ data }) => {
        this.clickedItem.createDate = this.formatDate(
          new Date(data.createDate),
          'yyyy/MM/dd hh:mm:ss',
        );
        this.clickedItem.createrId = data.createrId;
        this.clickedItem.groupId = data.groupId;
        this.clickedItem.groupName = data.groupName;

        // 클릭한 권한정보의 사용권한 리스트
        for (let i = 0; i < data.menus.length; i++) {
          // span 값에 따라 나머지 menus.span 값 0
          if (data.menus[i].span > 1) {
            for (let j = 1; j < data.menus[i].span; j++) {
              data.menus[i + j].span = 0;
            }
          }

          if (data.menus[i].groupMenuId) {
            this.useSelectedList.push(data.menus[i].groupMenuId);
          }
        }
        this.clickedItem.menus = data.menus;
        this.tabOverlay = false;
      });
    },
    async openPopup(type) {
      this.openPopType = type;
      if (type == 'update') {
        if (!this.clickGroupId) {
          const target = this.getPostWord(
            this.$i18n.t('operation.group.target-group'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-update', { target }),
          );
          return;
        }
      }
      this.popShow = true;
    },
    async allowRowsDel(item) {
      const groupId = item.groupId;
      const result = await this.$dialogs.confirm(
        `<p>${this.$i18n.t('operation.group.target-group')} : <span>${
          item.groupName
        }</span> (<span class="c-orange">${groupId}</span>)</p>${this.$i18n.t(
          'common.alert.delete-confirm',
        )}`,
      );
      if (result.ok) {
        const passwordChk = await this.$dialogs.password();
        if (passwordChk.ok) {
          try {
            await deleteOperAllow({ groupId }).then(({ data }) => {
              if (data.valid) {
                this.$dialogs
                  .alert(this.$i18n.t('common.alert.delete-success'))
                  .then(() => {
                    const reqbody = {
                      page: 1,
                      rows: this.nowPerPage,
                      sidx: '',
                      sord: 'asc',
                      groupName: '',
                    };
                    this.getAuthList(reqbody);
                    this.clickedItem = {
                      createDate: null,
                      createrId: '',
                      groupId: '',
                      groupName: '',
                      menus: [],
                    };
                  });
              }
            });
          } catch (error) {
            console.log('error', error);
          }
        }
      }
    },
    async allowUpdate() {
      if (this.useSelectedList.length < 1) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-select', {
            target: this.$i18n.t('operation.group.target-limit'),
          }),
        );
        return;
      }

      const target = this.getPostWord(
        this.$i18n.t('operation.group.target-permission'),
        '을',
        '를',
      );

      const result = await this.$dialogs.confirm(
        this.$i18n.t('common.alert.update-confirm', { target }),
      );

      if (result.ok) {
        const passwordChk = await this.$dialogs.password();
        if (passwordChk.ok) {
          try {
            const params = {
              groupId: this.clickedItem.groupId,
              menuIds: this.useSelectedList,
            };
            await updateOperAllowMenu(params).then(({ data }) => {
              if (data.valid) {
                //
                this.$dialogs.alert(
                  this.$i18n.t('common.alert.update-success'),
                );
              } else {
                this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
              }
            });
          } catch (error) {
            console.log('error', error);
          }
        }
      }
    },
    // 상세 정보 초기화
    clearItem() {
      const clickedItem = {
        createDate: null,
        createrId: '',
        groupId: '',
        groupName: '',
        menus: [],
      };
      this.clickedItem = clickedItem;
    },
  },
  computed: {
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        createDate: this.tableItems[index].createDate
          ? this.formatDate(
              new Date(this.tableItems[index].createDate),
              'yyyy/MM/dd hh:mm:ss',
            )
          : '',
      }));
    },
    topPanelTitle() {
      return this.$i18n.t('operation.group.topPanel.title');
    },
    leftPanelTitle() {
      return this.$i18n.t('operation.group.leftPanel.title');
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.group.rightPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.group.topPanel.table-column1'),
          value: 'groupId',
          width: '13%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.topPanel.table-column2'),
          value: 'groupName',
          width: '28%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.topPanel.table-column3'),
          value: 'createDate',
          width: '28%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.topPanel.table-column4'),
          value: 'createrId',
          width: '16%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.topPanel.table-column5'),
          value: 'actions',
          width: '13%',
          sortable: false,
        },
      ];
    },
    menusHeaders() {
      return [
        {
          text: this.$i18n.t('operation.group.rightPanel.table-column1'),
          value: 'mainMenuName',
          width: '34%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.rightPanel.table-column2'),
          value: 'subMenuName',
          width: '33%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.group.rightPanel.table-column3'),
          value: 'groupMenuId',
          width: '33%',
          sortable: false,
        },
      ];
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      // 언어 설정 변경시 rightPanel의 메뉴명이 기존 언어로 남아있음 -> 패널 초기화
      this.clearItem();
    },
  },
};
</script>

<style scoped>
div.v-input--checkbox.v-input--dense {
  margin-top: -4px;
  margin-bottom: -18px;
}
</style>
