<template>
  <section>
    <two-panel
      :leftPanelWidth="'820'"
      :rightPanelWidth="'567'"
      :leftPanelTitle="'경진대회 신청자 관리'"
      :rightPanelTitle="'상세 정보'"
    >
      <template #leftPanel>
        <!-- 시스템 설정 목록 -->
        <table-list-component
          :tableHeight="'603'"
          ref="tableListComponent"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :customSlotInfo="customSlotInfo"
          :reloadToggle="reloadToggle"
          :loading="loading"
          @rowClick="rowClick"
          @getDataFromApi="getExhibitionSrchList"
        >
          <!-- 검색 옵션 -->
          <template #searchOptions>
            <label class="sort-label"> · 아이디</label>
            <input
              v-model="searchOpts.userId"
              type="text"
              class="sort-input-text"
              style="width:80px;"
              maxlength="100"
              dataType="LITERAL"
              @keyup.enter="keyUpEnter"
            />
            <label class="sort-label"> · 회원명</label>
            <input
              v-model="searchOpts.userNm"
              type="text"
              class="sort-input-text"
              style="width:80px;"
              maxlength="100"
              dataType="LITERAL"
              @keyup.enter="keyUpEnter"
            />
            <label class="sort-label"> · 등록일시</label>
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.dateRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    dataType="LITERAL"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                  />
                  <label
                    for="findDateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>
          <!-- customSlot -->
          <template v-slot:userNm_custom="{ item }">
            <td class="text-center td-ellipsis" :title="item.userNm">
              {{ item.userNm || '-' }}
            </td>
          </template>
          <template v-slot:userMbtlnum_custom="{ item }">
            <td class="text-center" :title="item.userMbtlnum">
              {{ getPhoneNoText(item.userMbtlnum) || '-' }}
            </td>
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <template v-slot:userMbtlnum_tab_custom="{ item }">
            <td>
              <span>{{ getPhoneNoText(item.value) || '-' }}</span>
            </td>
          </template>
          <template v-slot:userCbleTelno_tab_custom="{ item }">
            <td>
              <span>{{ getPhoneNoText(item.value) || '-' }}</span>
            </td>
          </template>
          <template v-slot:svcCn_tab_custom="{ item }">
            <td>
              <textarea
                class="input-24 w-100 board-readonly"
                rows="5"
                maxlength="1500"
                style="height:300px; overflow-y:auto;"
                readonly="readonly"
                :value="item.value || '-'"
              ></textarea>
            </td>
          </template>
          <template v-slot:pledge_tab_custom>
            <td>
              <input
                type="button"
                value="서약서 보기"
                class="bt-line24 cr-gray"
                @click="showPledge"
                style="margin-top:2px; margin-bottom: 2px; margin-left: 1px;"
              />
            </td>
          </template>
        </table-tab-component>
      </template>
    </two-panel>
    <!-- 서약서 팝업 -->
    <div title="서약서" class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:435px;">
        <div class="pop-head">
          <h2>서약서</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <div class="pop-body-wrapper">
            <div class="pop-body-title">
              <p>2020 건강관리 서비스 개발 경진대회 참가 서약서</p>
            </div>
            <div class="pop-body-content">
              <p>
                참가자 본인은 2020 개방형 헬스케어 플랫폼(Redwood) 기반 건강관리
                서비스 개발 경진대회 공고문에 대한 내용을 숙지하였으며,
                신의성실을 다하여 참여 및 서비스 개발 시 관계 법령 및 규정을
                준수할 것이며, 또한 아래 항목에 대한 동의 및 경진대회에 참가함을
                서약합니다.
              </p>
            </div>
            <div class="pop-body-separate">
              <p>
                - 아 래 -
              </p>
            </div>
            <div class="pop-body-stplat">
              <dl>
                <dd>
                  1. 본 경진대회의 선정 방식 및 이와 관련된 공정한 심사와
                  객관적인 내부 절차에 의한 제반 결정에 이의를 제기하지
                  않겠습니다.
                </dd>
                <dd>
                  2. 개발되는 서비스의 모방, 표절로 인정되는 사실이 있는 경우,
                  선정 이후라 하더라도 시상 취소, 시상금 환수 등의 결정에 이의를
                  제기하지 않겠습니다.
                </dd>
                <dd>
                  3. 서비스 개발을 수행하는 과정에서 타인의 지식재산권 및 기타
                  권리를 이용하는 경우, 권리 당사자와 직접 협의하여 정당한
                  이용이 될 수 있도록 하며, 수행 결과물을 활용과 관련된 분쟁에
                  대한 책임은 신청인에게 있음을 서약합니다.
                </dd>
              </dl>
              <p id="p_creatDt" class="pop-body-stplat-date">
                {{
                  dateStringFormat(
                    common_getDateString(
                      this.tabItems[0].selectedItem.creatDt.value,
                    ),
                  )
                }}
              </p>
            </div>
            <div class="pop-body-info">
              <span
                >참가자:
                <b>{{ this.tabItems[0].selectedItem.userNm.value }}</b></span
              >
              <br />
              <span
                >연락처:
                <b>{{
                  getPhoneNoText(
                    this.tabItems[0].selectedItem.userMbtlnum.value,
                  )
                }}</b></span
              >
            </div>
            <div class="pop-body-signature">
              <span>헬스케어 플랫폼(Redwood)</span>
              <br />
              <span>DKI테크놀로지 귀중</span>
            </div>
          </div>
          <p class="pop-btn-group">
            <input
              type="button"
              value="인쇄"
              class="bt-line30 cr-orange"
              @click="printSection"
            />
            <input
              type="button"
              value="닫기"
              class="bt-line30"
              @click="closePopup"
            />
          </p>
        </div>
      </div>
    </div>

    <!--서약서 인쇄-->
    <div class="print">
      <div id="print" class="print-wrapper">
        <div class="print-title" style="text-align: center;">
          <p
            style="font-size: 26px; line-height: 1.6; margin-bottom: 45px; font-weight: bold;"
          >
            2020 건강관리 서비스 개발 경진대회 참가 서약서
          </p>
        </div>
        <div class="print-content-wrapper">
          <div class="print-content" style="text-align: center; ">
            <p style="font-size: 18px; line-height: 1.6; margin-bottom: 45px;">
              참가자 본인은 2020 개방형 헬스케어 플랫폼(Redwood) 기반 건강관리
              <br />
              서비스 개발 경진대회 공고문에 대한 내용을 숙지하였으며, 신의성실을
              <br />
              다하여 참여 및 서비스 개발 시 관계 법령 및 규정을 준수할 것이며,
              <br />
              또한 아래 항목에 대한 동의 및 경진대회에 참가함을 서약합니다.
            </p>
          </div>
          <div
            class="print-separate"
            style="text-align: center; margin-bottom: 60px;"
          >
            <p style="font-size: 18px; line-height: 1.6; ">
              - 아 래 -
            </p>
          </div>
          <div
            class="print-stplat"
            style="text-align: center; font-size: 18px; line-height: 1.6; margin-bottom: 35px;"
          >
            <dl style="margin-bottom: 55px;">
              <dd style="margin-bottom: 35px;">
                1. 본 경진대회의 선정 방식 및 이와 관련된 공정한 심사와 객관적인
                <br />
                내부 절차에 의한 제반 결정에 이의를 제기하지 않겠습니다.
                <br />
              </dd>
              <dd style="margin-bottom: 35px;">
                2. 개발되는 서비스의 모방, 표절로 인정되는 사실이 있는 경우,
                <br />
                선정 이후라 하더라도 시상 취소, 시상금 환수 등의 결정에 이의를
                <br />
                제기하지 않겠습니다.
                <br />
              </dd>
              <dd style="margin-bottom: 35px;">
                3. 서비스 개발을 수행하는 과정에서 타인의 지식재산권 및 기타
                <br />
                권리를 이용하는 경우, 권리 당사자와 직접 협의하여 정당한 이용이
                <br />
                될 수 있도록 하며, 수행 결과물을 활용과 관련된 분쟁에 대한
                <br />
                책임은 신청인에게 있음을 서약합니다.
              </dd>
            </dl>
            <p
              id="p_creatDt"
              class="pop-body-stplat-date"
              style="text-align: center;"
            >
              {{
                dateStringFormat(
                  common_getDateString(
                    this.tabItems[0].selectedItem.creatDt.value,
                  ),
                )
              }}
            </p>
          </div>
        </div>
        <div class="print-signature-wrapper" style="margin-top: 90px;">
          <div
            class="print-info"
            style="text-align: right; font-size: 18px; line-height: 1.6; margin-bottom: 55px; padding-right: 100px"
          >
            <span
              >참가자:
              <b>{{ this.tabItems[0].selectedItem.userNm.value }}</b></span
            >
            <br />
            <span
              >연락처:
              <b>{{
                getPhoneNoText(this.tabItems[0].selectedItem.userMbtlnum.value)
              }}</b></span
            >
          </div>
          <div
            class="print-signature"
            style="text-align: center; font-size: 18px; line-height: 1.6; font-weight: bold;"
          >
            <span><b>헬스케어 플랫폼(Redwood)</b></span>
            <br />
            <span><b>DKI테크놀로지 귀중</b></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import { getExhibitionList, getExhibitionDetail } from '@/api/operationApi';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
  },
  data() {
    return {
      // 좌측 패널 테이블
      loading: false,
      reloadToggle: true,
      headers: [
        { text: '아이디', value: 'userId', width: '25%', sortable: false },
        { text: '회원명', value: 'userNm', width: '25%', sortable: false },
        { text: '휴대폰', value: 'userMbtlnum', width: '25%', sortable: false },
        { text: '신청일시', value: 'creatDt', width: '25%' },
      ],
      // custom Slot
      customSlotInfo: [
        { name: 'userNm', slotName: 'item.userNm' },
        { name: 'userMbtlnum', slotName: 'item.userMbtlnum' },
      ],

      tableItems: [],
      // 검색 정보
      searchOpts: {
        userId: '',
        userNm: '',
        dateRange: '',
      },
      // row
      nowPerPage: 30,
      totalRecords: 0,
      totalPages: 0,
      // 우측 패널 테이블
      tabOverlay: false,
      tabItems: [
        {
          id: 'detail',
          title: '상세정보',
          headers: [
            { text: '항목', width: '20%' },
            { text: '내용', width: '80%' },
          ],
          selectedItem: {
            userId: { name: '아이디', value: '' },
            teamNm: { name: '업체명/팀명', value: '' },
            userNm: { name: '성명(대표자)', value: '' },
            userEmail: { name: '이메일', value: '' },
            userMbtlnum: { name: '휴대폰', value: '', isCustom: true },
            userCbleTelno: { name: '전화', value: '', isCustom: true },
            userAdres: { name: '주소', value: '' },
            svcCn: { name: '서비스 내용', value: '', isCustom: true },
            pledge: { name: '서약서', value: '', isCustom: true },
            creatDt: { name: '생성일자', value: '', isHidden: true },
          },
        },
      ],
      popup: {
        show: false,
      },
    };
  },
  methods: {
    async getExhibitionSrchList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        userId,
        userNm,
        dateRange,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        dateRange,
        userId: userId.trim(),
        userNm: userNm.trim(),
        startDateString: dateRange.start,
        endDateString: dateRange.end,
      };
      await getExhibitionList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    async rowClick(item) {
      this.tabOverlay = true;
      await getExhibitionDetail({ userId: item.userId }).then(({ data }) => {
        const keys = Object.keys(this.tabItems[0].selectedItem);
        keys.forEach(key => {
          this.tabItems[0].selectedItem[key].value = data.detail[key];
        });
      });
      this.tabOverlay = false;
    },
    showPledge() {
      if (!this.tabItems[0].selectedItem.userId.value) {
        this.$dialogs.alert('서약서를 볼 항목을 선택해주세요.');
        return;
      }
      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
    },
    printSection() {
      this.$htmlToPaper('print');
    },
    common_getDateString(longValue) {
      if (longValue) {
        var date = new Date(longValue);

        return (
          date.getFullYear() +
          '/' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + date.getDate()).slice(-2) +
          ' ' +
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + date.getMinutes()).slice(-2) +
          ':' +
          ('0' + date.getSeconds()).slice(-2)
        );
      } else {
        return '';
      }
    },
    dateStringFormat(dateString) {
      if (dateString) {
        var date = new Date(dateString);

        return (
          date.getFullYear() +
          '년 ' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '월 ' +
          ('0' + date.getDate()).slice(-2) +
          '일'
        );
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.pop-body-wrapper {
  border-bottom: solid 1px #999999;
}

.pop-body-title {
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 25px;
  font-weight: bold;
}

.pop-body-content {
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.pop-body-separate {
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.pop-body-stplat {
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.pop-body-stplat dl {
  margin-bottom: 25px;
}

.pop-body-stplat dl > dd {
  margin-bottom: 15px;
}

.pop-body-stplat-date {
  text-align: center;
}

.pop-body-info {
  text-align: right;
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.pop-body-signature {
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
  margin-bottom: 25px;
  font-weight: bold;
}

.print {
  display: none;
  width: 100%;
  height: 100%;
}

.print-wrapper {
  padding: 100px 100px 0px 100px;
}

.print-title {
  text-align: center;
  font-size: 26px;
  line-height: 1.6;
  margin-bottom: 45px;
  font-weight: bold;
}

.print-content {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 45px;
}

.print-separate {
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 60px;
}

.print-stplat {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 35px;
}

.print-stplat dl {
  margin-bottom: 55px;
}

.print-stplat dl > dd {
  margin-bottom: 35px;
}

.print-stplat-date {
  text-align: center;
}

.print-signature-wrapper {
  margin-top: 90px;
}

.print-info {
  text-align: right;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 55px;
}

.print-signature {
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  font-weight: bold;
}
</style>
