<template>
  <!-- 관리자 권한 기본 정보 수정 창-->
  <div v-if="popShow && openPopType == 'update'" class="pop-drag">
    <div class="popup-mask"></div>
    <div class="pop-content pop-ani pop-drag panel" style="width:400px;">
      <div class="pop-head">
        <h2>{{ $t('operation.group.updatePopUp.title') }}</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="updatePopForm">
            <table class="table-style-default table-pop mt-10">
              <colgroup>
                <col width="27%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ groupId }}</th>
                  <td>
                    <span>{{ updateItem.groupId }}</span>
                  </td>
                </tr>
                <tr>
                  <th>{{ groupName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="groupName"
                      :rules="{
                        required: true,
                        min: 2,
                        max: 20,
                      }"
                      inputClass="input-24 w-100"
                      inputStyle="width:148px"
                      maxlength="20"
                      required
                      autocomplete="off"
                      v-model="updateItem.groupName"
                      @input="groupNmDuplChk = false"
                      @keydown="inputLiteralCheck"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      @click="dupliCheck()"
                      id="update_nameDuplicationCheck"
                    >
                      {{ $t('common.btn.check-dupl') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnSubmit"
              class="bt-line30 cr-orange"
              @click="submit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { operAllowDupliCheck, updateOperAllow } from '@/api/operationApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    clickedItem: {
      type: Object,
      required: true,
    },
    nowPerPage: {
      type: Number,
      required: true,
    },
  },
  components: { ValidateForm },
  mixins: [CommonMixins],
  data: () => ({
    updateItem: {
      createDate: null,
      createrId: '',
      groupId: '',
      groupName: '',
      menus: [],
    },
    groupNmDuplChk: false,
  }),
  computed: {
    groupId() {
      return this.$i18n.t('operation.group.updatePopUp.label1');
    },
    groupName() {
      return this.$i18n.t('operation.group.updatePopUp.label2');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
  },
  methods: {
    async dupliCheck() {
      if (this.clickedItem.groupName == this.updateItem.groupName) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.update-fail-sameBefore'),
        );
      } else {
        const params = {
          groupIdOrName: this.updateItem.groupName.trim(),
          type: 'name',
        };
        await operAllowDupliCheck(params).then(({ data }) => {
          const target = this.groupName;
          if (!data.valid) {
            this.$dialogs.alert(
              this.$i18n.t('common.alert.invalid-target-inUse', { target }),
            );
          } else {
            this.$dialogs.alert(
              this.$i18n.t('common.alert.valid-target', { target }),
            );
            this.groupNmDuplChk = true;
          }
        });
      }
    },
    async submit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      if (!this.groupNmDuplChk) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-dupl', { field: this.groupName }),
        );
        return;
      } else {
        const pwChckResult = await this.$dialogs.password();
        if (pwChckResult.ok) {
          const params = {
            groupId: this.clickedItem.groupId,
            groupName: this.updateItem.groupName,
          };
          await updateOperAllow(params).then(({ data }) => {
            const { valid } = data;
            if (!valid) {
              this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
              return;
            }
            this.$dialogs
              .alert(this.$i18n.t('common.alert.update-success'))
              .then(() => {
                const reqbody = {
                  page: 1,
                  rows: this.nowPerPage,
                  sidx: '',
                  sord: 'asc',
                  groupName: '',
                };
                this.$emit('getList', reqbody);
                this.$emit('updateItem', this.updateItem);
              })
              .finally(() => {
                this.closePopup();
              });
          });
        }
      }
    },
    closePopup() {
      this.$emit('close', false);
      this.clearSetting();
    },
    clearSetting() {
      this.updateItem.groupName = '';
      this.updateItem.groupId = '';
      this.updateItem.createrId = '';
      this.updateItem.createDate = null;
      this.updateItem.menus = [];
      this.groupNmDuplChk = false;
    },
    setUpdateItem() {
      this.updateItem.groupId = this.clickedItem.groupId;
      this.updateItem.groupName = this.clickedItem.groupName;
      this.updateItem.createDate = this.clickedItem.createDate;
      this.updateItem.createrId = this.clickedItem.createrId;
      this.updateItem.menus = this.clickedItem.menus;
    },
    resetUpdateItem() {
      Object.keys(this.updateItem).map(k => (this.updateItem[k] = ''));
    },
  },
  watch: {
    clickedItem: {
      deep: true,
      handler() {
        if (!(this.clickedItem.groupId == '')) {
          this.setUpdateItem();
        } else {
          this.resetUpdateItem();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
