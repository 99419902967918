<template>
  <section>
    <two-panel
      :leftPanelWidth="'820'"
      :rightPanelWidth="'567'"
      :leftPanelTitle="leftPanelTitle"
      :rightPanelTitle="rightPanelTitle"
    >
      <template #leftPanel>
        <table-list-component
          ref="tableListComponent"
          :loading="loading"
          :headers="headers"
          :items="tableItems"
          :nowPerPage="nowPerPage"
          :totalRecords="totalRecords"
          :totalPages="totalPages"
          :searchOpts="searchOpts"
          :tableHeight="'603'"
          :reloadToggle="reloadToggle"
          @getDataFromApi="getNoticeSrchList"
          @rowClick="rowClick"
        >
          <!-------------- 검색 옵션 -------------->
          <template #searchOptions>
            <label class="sort-label"
              >· {{ $t('operation.notice.search-condition1') }}</label
            >
            <input
              type="text"
              v-model="searchOpts.noticeSj"
              class="sort-input-text"
              maxlength="20"
              @keyup.enter="keyUpEnter"
              @keydown="inputLiteralCheck"
            />

            <label class="sort-label"
              >· {{ $t('operation.notice.search-condition2') }}</label
            >
            <span class="daterange-wrap">
              <vc-date-picker
                v-model="searchOpts.dateRange"
                mode="date"
                :columns="$screens({ default: 1, lg: 2 })"
                is-range
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <input
                    type="text"
                    class="sort-input-text"
                    style="width: 170px;"
                    id="findDateDaterangepicker"
                    :value="
                      inputValue.start != null
                        ? `${inputValue.start} ~ ${inputValue.end}`
                        : null
                    "
                    autocomplete="off"
                    @click="togglePopover()"
                    @keyup.enter="keyUpEnter"
                    @keydown="inputLiteralCheck"
                  />
                  <label
                    for="findDateDaterangepicker"
                    class="ic-calendar"
                  ></label>
                </template>
              </vc-date-picker>
            </span>
          </template>
          <!------------------------    공지사항 등록 버튼   ---------------------------->
          <template #subHeaderItem>
            <input
              type="button"
              :value="btnRegister"
              class="bt-line24 cr-orange"
              @click="openPopup('insert')"
            />
          </template>
        </table-list-component>
      </template>
      <template #rightPanel>
        <table-tab-component
          :tabItems="tabItems"
          :tabOverlay="tabOverlay"
          :tableHeight="'710'"
        >
          <!-- custom slot -->
          <template v-slot:noticeCn_tab_custom="{ item }">
            <td style="padding: 2px 7px 2px 7px;">
              <textarea
                style="height:200px; overflow-y:auto; color:black; "
                class="input-24 w-100"
                :value="item.value || '-'"
                disabled
              >
              </textarea>
            </td>
          </template>
          <template v-slot:fileNm_tab_custom="{ item }">
            <td v-if="item.value" @click="fileDownload" style="cursor:pointer;">
              {{ item.value }}
            </td>
            <td v-else>
              -
            </td>
          </template>

          <!-- bottom에 버튼 -->
          <template #noticeInfo_bottom_button>
            <div class="table-bot-btns">
              <p class="f-right">
                <input
                  type="button"
                  :value="btnUpdate"
                  class="bt-line24 cr-gray"
                  style="margin-right: 5px;"
                  @click="openPopup('update')"
                />
                <input
                  type="button"
                  id="button_delete_app"
                  :value="btnDelete"
                  class="bt-line24 cr-gray"
                  @click="goDelNotice"
                />
                <!-- @click="goDeleteQna" -->
              </p>
            </div>
          </template>
        </table-tab-component>
      </template>
    </two-panel>

    <!-- 등록/수정 팝업 -->
    <div class="pop-drag" v-if="popup.show">
      <div class="popup-mask"></div>
      <div class="pop-content pop-ani pop-drag" style="width:500px">
        <div class="pop-head">
          <h2>{{ popup.title }}</h2>
          <button class="btn-x" @click="closePopup"></button>
        </div>
        <div class="pop-body">
          <validation-observer ref="observer">
            <form id="InsertForm" enctype="multipart/form-data">
              <table class="table-style-default table-pop">
                <colgroup>
                  <col width="106px" />
                  <col width="*" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ noticeSubject }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        :label="noticeSubject"
                        :rules="{ required: true, max: 50 }"
                        inputClass="input-24 w-100"
                        maxlength="50"
                        v-model="popup.item.noticeSj"
                        @keydown="inputLiteralCheck"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ noticeContent }}<i class="star">*</i></th>
                    <td>
                      <validate-form
                        type="textarea"
                        :label="noticeContent"
                        rows="5"
                        :rules="{ required: true, max: 1500 }"
                        inputClass="input-24 w-100"
                        v-model="popup.item.noticeCn"
                        maxlength="1500"
                        inputStyle="height:200px; overflow-y:auto;"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('operation.notice.popUp.label3') }}</th>
                    <td>
                      <input
                        v-model="fileNm"
                        type="text"
                        class="input-24"
                        style="width:250px"
                        readonly="readonly"
                      />
                      <input
                        type="file"
                        ref="file"
                        name="multi"
                        style="display:none"
                        @change="changeFile"
                      />
                      <input
                        type="button"
                        class="bt-line24"
                        :value="btnSelect"
                        alt="open"
                        @click="$refs.file.click()"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <p class="pop-btn-group">
              <input
                type="button"
                :value="popup.type == 'insert' ? btnRegister : btnSubmit"
                class="bt-line30 cr-orange"
                @click="
                  popup.type == 'insert' ? insertSubmit() : updateSubmit()
                "
              />
              <input
                type="button"
                :value="btnCancel"
                class="bt-line30"
                @click="closePopup"
              />
            </p>
          </validation-observer>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TwoPanel from '@/layouts/TwoPanel';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import {
  getNoticeList,
  getNoticeDetail,
  insertNotice,
  updateNotice,
  delNotice,
} from '@/api/operationApi';
import ValidateForm from '@/components/ValidateForm.vue';

export default {
  mixins: [mixins],
  components: {
    TwoPanel,
    TableListComponent,
    TableTabComponent,
    ValidateForm,
  },
  data: () => ({
    loading: false,
    // --- 공지사항 검색 정보 --- //
    searchOpts: {
      noticeSj: '',
      dateRange: {
        start: '',
        end: '',
      },
    },
    // --- Total row 개수 --- //
    totalRecords: 0,
    totalPages: 0,
    tableItems: [],
    nowPerPage: 30,
    reloadToggle: true,

    // --- custom Slot --- //
    customSlotInfo: [],

    // -- 우측 패널 테이블 -- //
    tabOverlay: false,
    tabItems: [],
    // 팝업
    popup: {
      type: '',
      show: false,
      item: { noticeNo: '', noticeSj: '', noticeCn: '' },
    },

    fileNm: '',
    file: {},
  }),
  created() {
    this.tabItems = this.tabItemsComputed;
  },
  computed: {
    leftPanelTitle() {
      return this.$i18n.t('operation.notice.leftPanel.title');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('operation.notice.leftPanel.table-column1'),
          value: 'noticeSj',
          width: '50%',
        },
        {
          text: this.$i18n.t('operation.notice.leftPanel.table-column2'),
          value: 'rdcnt',
          width: '15%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.notice.leftPanel.table-column3'),
          value: 'creatDt',
          width: '35%',
        },
      ];
    },
    rightPanelTitle() {
      return this.$i18n.t('operation.notice.rightPanel.title');
    },
    tabItemsComputed() {
      return [
        {
          id: 'noticeInfo',
          headers: [
            {
              text: this.$i18n.t('operation.notice.rightPanel.header1'),
              width: '30%',
            },
            {
              text: this.$i18n.t('operation.notice.rightPanel.header2'),
              width: '70%',
            },
          ],
          selectedItem: {
            noticeNo: {
              name: this.$i18n.t('operation.notice.rightPanel.label1'),
              value: '',
            },
            noticeSj: {
              name: this.$i18n.t('operation.notice.rightPanel.label2'),
              value: '',
            },
            noticeCn: {
              name: this.$i18n.t('operation.notice.rightPanel.label3'),
              value: '',
              isCustom: true,
            },
            fileNm: {
              name: this.$i18n.t('operation.notice.rightPanel.label4'),
              value: '',
              isCustom: true,
            },
            rdcnt: {
              name: this.$i18n.t('operation.notice.rightPanel.label5'),
              value: '',
            },
            crtrId: {
              name: this.$i18n.t('operation.notice.rightPanel.label6'),
              value: '',
            },
            creatDt: {
              name: this.$i18n.t('operation.notice.rightPanel.label7'),
              value: '',
            },
            updusrId: {
              name: this.$i18n.t('operation.notice.rightPanel.label8'),
              value: '',
            },
            updtDt: {
              name: this.$i18n.t('operation.notice.rightPanel.label9'),
              value: '',
            },
          },
        },
      ];
    },
    noticeSubject() {
      return this.$i18n.t('operation.notice.popUp.label1');
    },
    noticeContent() {
      return this.$i18n.t('operation.notice.popUp.label2');
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnUpdate() {
      return this.$i18n.t('common.btn.update');
    },
    btnDelete() {
      return this.$i18n.t('common.btn.delete');
    },
    btnSelect() {
      return this.$i18n.t('common.btn.select');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    lang() {
      this.tabItems = this.tabItemsComputed;
    },
  },
  methods: {
    // 공지사항 리스트 조회
    async getNoticeSrchList(searchParams) {
      this.loading = true;
      const { rows, page, sord, sidx, noticeSj, dateRange } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        noticeSj,
        startDateString: !dateRange.start
          ? ''
          : this.formatDate(new Date(dateRange.start), 'yyyy-MM-dd'),
        endDateString: !dateRange.end
          ? ''
          : this.formatDate(new Date(dateRange.end), 'yyyy-MM-dd'),
      };
      await getNoticeList(params).then(res => {
        const { rows, totalRecords, totalPages } = res.data;
        this.tableItems = rows;
        this.totalRecords = totalRecords;
        this.totalPages = totalPages;
        this.loading = false;
      });
    },
    // row 클릭 시 상세 조회
    async rowClick(item) {
      this.tabOverlay = true;
      await getNoticeDetail({ noticeNo: item.noticeNo })
        .then(({ data }) => {
          const keys = Object.keys(this.tabItems[0].selectedItem);
          keys.forEach(key => {
            this.tabItems[0].selectedItem[key].value = data.detail[key];
          });
        })
        // 404 error 처리 진행중
        .catch(error => {
          console.log(error);
        });
      this.tabOverlay = false;
    },
    openPopup(type) {
      this.popup.type = type;
      if (this.popup.type == 'update') {
        this.popup.title = this.$i18n.t('operation.notice.popUp.title-update');
        const selectedItem = this.tabItems[0].selectedItem;
        if (!selectedItem.noticeNo.value) {
          const target = this.getPostWord(
            this.$i18n.t('operation.notice.target-notice'),
            '을',
            '를',
          );
          this.$dialogs.alert(
            this.$i18n.t('common.alert.target-update', { target }),
          );
          return;
        }
        Object.keys(this.popup.item).forEach(key => {
          this.popup.item[key] = selectedItem[key]?.value;
        });
      } else {
        this.popup.title = this.$i18n.t('operation.notice.popUp.title-insert');
      }
      this.popup.show = true;
    },
    closePopup() {
      this.popup.show = false;
      Object.keys(this.popup.item).forEach(key => {
        this.popup.item[key] = '';
      });
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    fileDownload() {
      window.location.href = `${process.env.VUE_APP_URL_MAS_API}/operation/notice/file/${this.tabItems[0].selectedItem.noticeNo.value}?token=${this.$store.state.auth.token}`;
    },
    changeFile() {
      this.file = this.$refs.file.files[0];
      this.fileNm = this.$refs.file.files[0].name;
    },
    async insertSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      const params = {
        multi: this.file,
        noticeSj: this.popup.item.noticeSj,
        noticeCn: this.popup.item.noticeCn,
      };

      await insertNotice(params).then(({ data }) => {
        const { valid } = data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
          return;
        }
        this.$dialogs
          .alert(this.$i18n.t('common.alert.insert-success'))
          .then(() => {
            this.closePopup();
            this.reloadToggle = !this.reloadToggle;
            // 상세보기 초기화
            this.clearTabItems();
          });
      });
    },
    async updateSubmit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }

      const noticeNo = this.tabItems[0].selectedItem.noticeNo.value;
      const params = {
        noticeNo: noticeNo,
        multi: this.file,
        noticeSj: this.popup.item.noticeSj,
        noticeCn: this.popup.item.noticeCn,
      };
      await updateNotice(params).then(({ data }) => {
        const { valid } = data;
        if (!valid) {
          this.$dialogs.alert(this.$i18n.t('common.alert.update-fail'));
          return;
        }
        this.$dialogs
          .alert(this.$i18n.t('common.alert.update-success'))
          .then(() => {
            this.closePopup();
            this.reloadToggle = !this.reloadToggle;
            // 상세보기 최신화
            this.rowClick({ noticeNo });
          });
      });
    },
    async goDelNotice() {
      const noticeNo = this.tabItems[0].selectedItem.noticeNo.value;
      if (!noticeNo) {
        const target = this.getPostWord(
          this.$i18n.t('operation.notice.target-notice'),
          '을',
          '를',
        );
        this.$dialogs.alert(
          this.$i18n.t('common.alert.target-delete', { target }),
        );
        return;
      }
      const result = await this.$dialogs.confirm(
        this.$i18n.t('common.alert.delete-confirm'),
      );
      if (result.ok) {
        await delNotice({ noticeNo: noticeNo }).then(({ data }) => {
          if (data.valid) {
            this.$dialogs
              .alert(this.$i18n.t('common.alert.delete-success'))
              .then(() => {
                this.closePopup();
                this.reloadToggle = !this.reloadToggle;
                // 상세보기 초기화
                this.clearTabItems();
              });
          }
        });
      }
    },

    // 우측 패널 데이터 초기화
    clearTabItems() {
      //  선택 정보 clear
      Object.keys(this.tabItems[0].selectedItem).forEach(key => {
        this.tabItems[0].selectedItem[key].value = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
