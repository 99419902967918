<template>
  <!------------------------    관리자 등록 팝업   ---------------------------->
  <div class="pop-drag" v-if="popShow && openPopType == 'insert'">
    <div class="popup-mask"></div>
    <div
      id="insertPopContent"
      class="pop-content pop-ani pop-drag"
      style="width:500px"
    >
      <div class="pop-head">
        <h2>{{ $t('operation.operator.insertPopUp.title') }}</h2>
        <button
          id="button_create_close"
          class="btn-x"
          @click="closePopup()"
        ></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <form id="insertPopForm">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="79px" />
                <col width="371px" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ operatorId }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="operatorId"
                      :rules="{
                        required: true,
                        regex: /[0-9A-Za-z\-_@.]{4,100}/,
                        min: 4,
                        max: 100,
                      }"
                      :errorData="operatorIdInputErr"
                      inputClass="input-24"
                      inputStyle="width:260px"
                      maxlength="100"
                      v-model="popup.submitItem.userId"
                      name="userId"
                      @input="operDuplicationChk = false"
                      @keydown="inputLiteralCheck"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      id="duplicate_check_button"
                      @click="operDupliBtn()"
                    >
                      {{ $t('common.btn.check-dupl') }}
                    </button>
                    <input
                      type="hidden"
                      value="N"
                      id="user_id_duplicate_check"
                    />
                    <p class="aler-t">
                      {{
                        $t('operation.operator.insertPopUp.input-guide', {
                          min: 4,
                          max: 100,
                        })
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>{{ operatorName }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="operatorName"
                      :rules="{ required: true, max: 100 }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="popup.submitItem.userName"
                      @keydown="inputLiteralCheck"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ password }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      :label="password"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                        min: 9,
                        max: 30,
                      }"
                      :errorData="pwInputErr"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="popup.submitItem.password"
                      dataType="PASSWORD"
                    />
                    <p class="aler-t">
                      {{
                        $t('operation.operator.insertPopUp.input-guide', {
                          min: 9,
                          max: 30,
                        })
                      }}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>{{ pwCheck }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      :label="pwCheck"
                      :rules="{
                        required: true,
                        confirmed: password,
                      }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      dataType="PASSWORD"
                      v-model="popup.submitItem.passwordChk"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ cellphone }}<i class="star">*</i></th>
                  <td>
                    <div class="tel-fill-style">
                      <select
                        class="input-24"
                        id="mphon_no1"
                        name="mphon_no1"
                        v-model="popup.submitItem.mphon_no1"
                      >
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                      </select>

                      <validate-form
                        type="number"
                        :label="cellphone"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="phoneInputErr"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="popup.submitItem.mphon_no2"
                        @keydown="inputNumberCheck"
                      />
                      <validate-form
                        type="number"
                        :label="cellphone"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="phoneInputErr"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="popup.submitItem.mphon_no3"
                        @keydown="inputNumberCheck"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ email }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      :label="email"
                      :rules="{ required: true, email: true }"
                      inputClass="input-24 w-100"
                      maxlength="50"
                      v-model="popup.submitItem.email"
                      name="emailAdr"
                      dataType="EMAIL"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('operation.operator.insertPopUp.label7')
                    }}<i class="star">*</i>
                  </th>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select"
                      :rules="{ required: true }"
                    >
                      <select
                        class="input-24 w-100"
                        id="user_group"
                        name="user_group"
                        v-model="popup.submitItem.userGroupNo"
                      >
                        <option
                          v-for="(value, key) in authGroup"
                          :key="key"
                          :value="key"
                          >{{ value }}</option
                        >
                      </select>
                      <label v-if="errors[0]" class="error-tt">{{
                        errors[0]
                      }}</label>
                    </validation-provider>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('operation.operator.insertPopUp.label8') }}</th>
                  <td>
                    <input
                      type="checkbox"
                      id="user_srvc_chk"
                      v-model="srvcChecked"
                    />
                    {{ $t('operation.operator.insertPopUp.adminYN') }}

                    <select
                      v-if="srvcChecked"
                      class="input-24 w-100 mt-3"
                      v-model="popup.submitItem.srvcId"
                    >
                      <option
                        v-for="(item, index) in srvcList.srvcNm"
                        :key="index"
                        :value="srvcList.srvcId[index]"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <select
                      v-else
                      class="input-24 w-100 mt-3"
                      disabled
                    ></select>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('operation.operator.insertPopUp.label9') }}</th>
                  <td>
                    <div class="panel">
                      <div class="panel-top-title">
                        <p class="panel-top-btns">
                          <input
                            type="button"
                            :value="btnAdd"
                            id="btnAddNewIpPop"
                            class="bt-line24 cr-orange"
                            @click="rowAppend()"
                          />
                        </p>
                      </div>
                      <div class="panel-body" id="ipGridPanelPop">
                        <v-data-table
                          dense
                          :headers="insertIpHeaders"
                          :items="ipListWithIndex"
                          :items-per-page="100"
                          hide-default-footer
                          class="elevation-1 text-center"
                        >
                          <template v-slot:[`item.index`]="{ item }">
                            <td
                              class="text-center table-index"
                              :title="`${item.index}`"
                            >
                              {{ item.index }}
                            </td>
                          </template>
                          <template v-slot:[`item.ip`]="{ item }">
                            <td
                              class="text-center"
                              :title="`${item.ip}`"
                              @click="ipRowClick(item.index)"
                            >
                              {{ item.ip }}
                            </td>
                          </template>
                          <template v-slot:[`item.actions`]="{ item }">
                            <td class="text-center">
                              <v-icon
                                :size="21"
                                color="indigo darken-2"
                                @click="ipRowsDel(item.index)"
                              >
                                mdi-delete
                              </v-icon>
                            </td>
                          </template>
                          <template v-slot:no-data>
                            {{ $t('common.pagination.label-view-noData') }}
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnRegister"
              class="bt-line30 cr-orange"
              id="button_create_add"
              @click="submit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { chkOperDuplication, insertOperator } from '@/api/operationApi';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    srvcList: {
      type: Object,
      required: true,
    },
    nowPerPage: {
      type: Number,
      required: true,
    },
    authGroup: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidateForm,
  },
  mixins: [CommonMixins],
  data: () => ({
    // 관리자 중복
    operDuplicationChk: false,
    // 서비스 관리자 체크 여부
    srvcChecked: false,
    popup: {
      type: '',
      submitItem: {
        userId: '',
        userName: '',
        userPassword: '',
        pwdConfirm: '',
        mphon_no1: '010',
        mphon_no2: '',
        mphon_no3: '',
        email: '',
        userGroupNo: 10000,
        errorData: {},
      },
    },
    // insertIpHeader
    ipList: [],
    ipv4Regex: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  }),
  methods: {
    // ip row del
    ipRowsDel(e) {
      this.ipList.splice(e - 1, 1);
    },
    // ip row 클릭시 팝업 오픈
    async ipRowClick(index) {
      const insertResult = await this.$dialogs.ipInsert();
      if (insertResult.ok) {
        this.$store.getters['operator/getIp'];
        this.ipList[index - 1].ip = this.$store.getters['operator/getIp'];
        this.ipList[index - 1].actions = '';
        this.$store.commit('operator/setIp', '');
      }
    },
    // ip row 추가
    rowAppend() {
      const newItem = { ip: '' };
      this.ipList.push(newItem);
    },
    // 관리자 중복확인
    async operDupliBtn() {
      if (!this.popup.submitItem.userId) {
        const field = this.getPostWord(this.operatorId, '을', '를');
        this.$dialogs.alert(
          this.$i18n.t('common.alert.required-field', { field }),
        );
        return;
      }
      const param = {
        userId: this.popup.submitItem.userId,
      };
      await chkOperDuplication(param).then(({ data }) => {
        if (data.valid) {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.valid-target', {
              target: this.operatorId,
            }),
          );
          this.operDuplicationChk = true;
        } else {
          this.$dialogs.alert(
            this.$i18n.t('common.alert.invalid-target-inUse', {
              target: this.operatorId,
            }),
          );
          this.operDuplicationChk = false;
        }
      });
    },
    closePopup() {
      this.$emit('close', false);
      this.clearSetting();
      // this.popup.show = false;
      Object.keys(this.popup.submitItem).forEach(key => {
        this.popup.submitItem[key] = '';
      });
    },
    async submit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      if (!this.operDuplicationChk) {
        const field = this.operatorId;
        this.$dialogs.alert(
          this.$i18n.t('common.alert.invalid-dupl', { field }),
        );
        return;
      }
      if (result && this.operDuplicationChk) {
        const params = {
          userId: this.popup.submitItem.userId.trim(),
          password: this.popup.submitItem.password.trim(),
          userName: this.popup.submitItem.userName.trim(),
          mobileNo:
            this.popup.submitItem.mphon_no1.trim() +
            this.popup.submitItem.mphon_no2.trim() +
            this.popup.submitItem.mphon_no3.trim(),
          email: this.popup.submitItem.email.trim(),
          userGroupNo: String(this.popup.submitItem.userGroupNo),
          srvcId: '',
          allowIps: [],
        };
        if (this.srvcChecked) {
          params.srvcId = this.popup.submitItem.srvcId;
        }
        for (let i = 0; i < this.ipList.length; i++) {
          // 입력값 검증
          if (this.ipList.length < 1) {
            return;
          }
          if (
            this.ipList[i].ip == '' ||
            (this.ipList[i].ip != '*' &&
              !this.ipv4Regex.test(this.ipList[i].ip))
          ) {
            this.$dialogs.alert(
              this.$i18n.t('common.alert.invalid-ip', {
                ip: this.ipList[i].ip,
              }),
            );
            return;
          }
          params.allowIps.push(this.ipList[i].ip);
        }
        const pwChckResult = await this.$dialogs.password();
        if (pwChckResult.ok) {
          await insertOperator(params)
            .then(({ data }) => {
              const { valid } = data;
              if (!valid) {
                this.$dialogs.alert(this.$i18n.t('common.alert.insert-fail'));
                return;
              }
              this.$dialogs
                .alert(this.$i18n.t('common.alert.insert-success'))
                .then(() => {
                  this.closePopup();
                  this.clearSetting();
                  const reqbody = {
                    page: 1,
                    rows: this.nowPerPage,
                    sidx: null,
                    sord: 'asc',
                    userId: '',
                    userName: '',
                    srvcId: '',
                  };
                  this.$emit('getOperatorList', reqbody);
                  this.$emit('close', false);
                });
            })
            .catch(error => {
              // 에러처리
              if (error.response.status === 409) {
                this.$dialogs.alert(error.response.data.message);
              }
              console.log('error', error);
            });
        }
      }
    },
    clearSetting() {
      this.popup.type = '';
      this.popup.submitItem.userId = '';
      this.popup.submitItem.userName = '';
      this.popup.submitItem.userPassword = '';
      this.popup.submitItem.pwdConfirm = '';
      this.popup.submitItem.mphon_no1 = '010';
      this.popup.submitItem.mphon_no2 = '';
      this.popup.submitItem.mphon_no3 = '';
      this.popup.submitItem.email = '';
      this.popup.submitItem.userGroupNo = 10000;
      this.ipList = [];
      this.srvcChecked = false;
      this.operDuplicationChk = false;
    },
  },
  computed: {
    ipListWithIndex() {
      return this.ipList.map((ipList, index) => ({
        ...ipList,
        index: index + 1,
      }));
    },
    operatorId() {
      return this.$i18n.t('operation.operator.insertPopUp.label1');
    },
    operatorName() {
      return this.$i18n.t('operation.operator.insertPopUp.label2');
    },
    password() {
      return this.$i18n.t('operation.operator.insertPopUp.label3');
    },
    pwCheck() {
      return this.$i18n.t('operation.operator.insertPopUp.label4');
    },
    cellphone() {
      return this.$i18n.t('operation.operator.insertPopUp.label5');
    },
    email() {
      return this.$i18n.t('operation.operator.insertPopUp.label6');
    },
    insertIpHeaders() {
      return [
        {
          text: '',
          value: 'index',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.operator.insertPopUp.header1'),
          value: 'ip',
          width: '70%',
          sortable: false,
        },
        {
          text: this.$i18n.t('operation.operator.insertPopUp.header2'),
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ];
    },
    operatorIdInputErr() {
      return { regex: this.$i18n.t('common.validate.id-format') };
    },
    pwInputErr() {
      return { regex: this.$i18n.t('common.validate.password-format') };
    },
    phoneInputErr() {
      return { regex: this.$i18n.t('common.validate.phone-format') };
    },
    btnRegister() {
      return this.$i18n.t('common.btn.register');
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnAdd() {
      return this.$i18n.t('common.btn.add');
    },
  },
};
</script>

<style lang="scss" scoped></style>
