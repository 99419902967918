<template>
  <!------------------------   비밀번호변경 팝업   ---------------------------->
  <div v-if="popShow && openPopType == 'changePw'" class="pop-drag">
    <div class="popup-mask"></div>
    <div
      id="changePasswordPopContent"
      class="pop-content pop-ani pop-drag"
      style="width:400px"
    >
      <div class="pop-head">
        <h2>{{ $t('operation.operator.changePwPopUp.title') }}</h2>
        <button class="btn-x" @click="closePopup()"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="observer">
          <!-- 안내 -->
          <div class="font-13">
            <label id="password_popup_id_label">
              {{ $t('operation.operator.changePwPopUp.label1') }}:
              <span class="c-orange">{{ clickUserId }}</span>
            </label>
            <br /><br />
            <div>
              <p v-html="$i18n.t('operation.operator.changePwPopUp.desc')"></p>
              <p class="mt-10 c-orange">
                {{
                  $t('operation.operator.changePwPopUp.input-guide', {
                    min: 9,
                    max: 30,
                  })
                }}
              </p>
              <br />
            </div>
          </div>
          <!-- 폼 -->
          <form id="changePasswordPopForm">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="45%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ operatorPw }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      :label="operatorPw"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                        min: 9,
                        max: 30,
                      }"
                      :errorData="pwInputErr"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="adminPW"
                      dataType="PASSWORD"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table-style-default table-pop mt-10">
              <colgroup>
                <col width="45%" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>{{ newPw }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      name="password"
                      :label="newPw"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                        min: 9,
                        max: 30,
                      }"
                      :errorData="pwInputErr"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="operPw"
                      dataType="PASSWORD"
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ newPwCheck }}<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      :label="newPwCheck"
                      :rules="{
                        required: true,
                        confirmed: newPw,
                      }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      dataType="PASSWORD"
                      v-model="operPwChk"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              :value="btnSubmit"
              class="bt-line30 cr-orange"
              @click="submit()"
            />
            <input
              type="button"
              :value="btnCancel"
              class="bt-line30"
              @click="closePopup()"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
import { checkPassword, updateOperatorPw } from '@/api/operationApi';

export default {
  props: {
    openPopType: {
      type: String,
      required: true,
    },
    popShow: {
      type: Boolean,
      required: true,
    },
    clickUserId: {
      type: String,
      required: true,
    },
  },
  components: {
    ValidateForm,
  },
  data: () => ({
    adminPW: '',
    operPw: '',
    operPwChk: '',
    errorData: {},
    adminPwChk: false,
  }),
  computed: {
    operatorPw() {
      return this.$i18n.t('operation.operator.changePwPopUp.label2');
    },
    newPw() {
      return this.$i18n.t('operation.operator.changePwPopUp.label3');
    },
    newPwCheck() {
      return this.$i18n.t('operation.operator.changePwPopUp.label4');
    },
    pwInputErr() {
      return { regex: this.$i18n.t('common.validate.password-format') };
    },
    btnCancel() {
      return this.$i18n.t('common.btn.cancel');
    },
    btnSubmit() {
      return this.$i18n.t('common.btn.submit');
    },
  },
  methods: {
    closePopup() {
      this.$emit('close', false);
      this.clearSetting();
    },
    clearSetting() {
      this.adminPW = '';
      this.operPw = '';
      this.operPwChk = '';
      this.adminPwChk = false;
    },
    async submit() {
      const result = await this.$refs.observer.validate();
      if (!result) {
        this.$dialogs.alert(this.$i18n.t('common.alert.required'));
        return;
      }
      console.log(result);
      // 접속한 관리자 비밀번호 확인
      await checkPassword({ password: this.adminPW }).then(({ data }) => {
        const { valid } = data;

        if (valid) {
          this.adminPwChk = true;
          const params = {
            admin_pwd: this.adminPW,
            newPassword: this.operPw,
            userId: this.clickUserId,
          };
          // 비밀번호 변경
          updateOperatorPw(params).then(({ data }) => {
            const { valid } = data;
            if (valid) {
              this.$dialogs.alert(this.$i18n.t('common.alert.update-success'));
              this.closePopup();
            }
          });
        } else {
          this.adminPwChk = false;
          this.$dialogs.alert(this.$i18n.t('common.alert.invalid-pw'));
        }
      });
    },
  },
};
</script>

<style scoped>
.mt-10 {
  margin-top: 10px !important;
}
</style>
